import { useState } from "react";
import { toast } from "react-toastify";

import { POST } from "../../../config";

import { LoginProps } from "../interfaces";
import { setUserLocalStorage } from "../../../helpers";
import { user_enums } from "../../../enums";

export const useLogin = (navigate: any) => {
  const [error, setError] = useState("");

  const login = async (user: LoginProps) => {
    
    try {
      const res = await POST(
        "/authenticate/login",
        JSON.stringify({
          username: user.username,
          password: user.password,
        }),
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      toast.success("Login successful");

      const data = res.data;

      setUserLocalStorage(user_enums.user, data.token);
      navigate("/dashboard");
    } catch (err: any) {
      console.log("error", err);
      toast.error("Error while logging into account");
      setError(err);
    }
  };
  return {
    login,
    error,
  };
};
