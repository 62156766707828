import { combineReducers, legacy_createStore as createStore, applyMiddleware } from "redux";
import { createLogger } from "redux-logger";
import thunk from "redux-thunk";

import { clientReducer, clientsReducer } from "../clients/reducer";
import { rolesReducer } from "../roles/reducer";
import { onboardingReducer } from "../clients/onboarding/reducer";
import { branchesReducer } from "../branches/reducer";
import {
  clientLoanRequestReducer,
  loanDisbursmentReducer,
  loanTypesReducer,
} from "../loans/reducer";
import { loanHistoryReducer } from "../clients/loanhistory/reducer";

const logger = createLogger();

const middlewares = [thunk, logger];

const rootReducer = combineReducers({
  clients: clientsReducer,
  client: clientReducer,
  roles: rolesReducer,
  onboarding: onboardingReducer,
  branches: branchesReducer,
  loantypes: loanTypesReducer,
  loanrequests: clientLoanRequestReducer,
  loanhistory: loanHistoryReducer,
  loandisbursements: loanDisbursmentReducer,
});

const store = createStore(rootReducer, applyMiddleware(...middlewares));

export default store;
