import fileInvoiceDollar from "@iconify/icons-fa6-solid/file-invoice-dollar";
import clockOutline from "@iconify/icons-mdi/clock-outline";
import autoPay from "@iconify/icons-mdi/auto-pay";
import fileDocumentTickOutline from "@iconify/icons-mdi/file-document-tick-outline";

import type { DashboardCardProps } from "../interfaces";

export const dashboardCardItems: DashboardCardProps[] = [
  {
    id: 1,
    title: "Total Invoices",
    total: 1000,
    currency: "Ksh",
    content: "Total invoices",
    amount: "500,000",
    icon: fileInvoiceDollar,
  },
  {
    id: 2,
    title: "Outstanding Invoices",
    total: 336,
    currency: "Ksh",
    content: "Unpaid by client",
    amount: "130,000",
    icon: clockOutline,
  },
  {
    id: 3,
    title: "Paid Invoices",
    total: 550,
    currency: "Ksh",
    content: "Fully paid by client",
    amount: "300,000",
    icon: fileDocumentTickOutline,
  },
  {
    id: 4,
    title: "Promise to pay",
    total: 114,
    currency: "Ksh",
    content: "To be paid",
    amount: "120,000",
    icon: autoPay,
  },
];
