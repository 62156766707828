import { useState } from "react";
import { toast } from "react-toastify";
import {getToken} from "../../../helpers"
import { POST } from "../../../config";

import type { AddClientProps } from "../interfaces";

export const useAddClient = (navigate: any) => {
  const [error, setError] = useState("");
  const token = getToken();

  const addclient = async (user: AddClientProps) => {
    try {
      const res = await POST(
        "/authenticate/register",
        JSON.stringify({
          username: user.username,
          email: user.email,
          password: user.password,
          role: user.role,
        }),
        {
          headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${token}`,
          },
        }
      );

      toast.success("User has been added successfully");
    } catch (err: any) {
      console.log("error", err);
      toast.error("Error while creating user");
      setError(err);
    }
  };
  return {
    addclient,
    error,
  };
};
