import React from "react";

import LoanRequestsModule from "../../modules/loans";

interface Props {}

const LoanRequestsPage: React.FC<Props> = () => {
  return (
    <>
      <LoanRequestsModule />
    </>
  );
};

export default LoanRequestsPage;
