import React from "react";
import { x } from "@xstyled/emotion";
import { Icon } from "@iconify/react";
import bankCheck from "@iconify/icons-mdi/bank-check";

import RegisterFormUI from "./elements";

import { spacing } from "../../theme";

const SignupModule = () => {
  return (
    <x.div
      pt={spacing[5]}
      h='100%'
      minHeight='40vh'
      display='flex'
      justifyContent='center'
      alignItems='center'
    >
      <x.div maxWidth='50rem' w='100%'>
        <x.div
          borderRadius={spacing[4]}
          minHeight='20rem'
          bg='white'
          boxShadow='0px 4px 4px 0px rgba(130, 130, 130, 0.4), 0px 3px 5px 2px rgba(130, 130, 130, 0.4)'
          display='flex'
          justifyContent='space-between'
          mb='1rem'
          mx={{ _: spacing[2], md: 0 }}
        >
          <x.div
            flex={0.4}
            p={spacing[4]}
            bg='primary-main'
            color='white'
            borderRadius='1rem 0 0 1rem'
          >
            <x.div display='flex' justifyContent='flex-start' mt='3rem'>
              <x.div
                display='flex'
                justifyContent='space-between'
                alignItems='center'
              >
                <Icon
                  icon={bankCheck}
                  style={{
                    fontSize: "2.5rem",
                  }}
                />
                <x.h3 fontSize='1.5rem' ml={spacing[2]} fontWeight='600'>
                  Sign Up
                </x.h3>
              </x.div>
            </x.div>
            <x.div
              my={spacing[10]}
              fontSize='1.35rem'
              lineHeight='1.6rem'
              fontWeight='500'
              color='secondary-100'
            >
              Taking care of what’s important
            </x.div>
            <x.p my={spacing[10]} lineHeight='1.3rem' fontSize='1rem'>
              This account will manage multiple delinquent accounts for debt
              collection efforts, keep track of assigned accounts to identify
              outstanding debts and negotiate payoff deadlines or payment plans.
            </x.p>
          </x.div>
          <x.div flex={1}>
            <RegisterFormUI />
          </x.div>
        </x.div>
      </x.div>
    </x.div>
  );
};

export default SignupModule;
