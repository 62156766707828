export const FETCH_LOAN_TYPES_REQUEST = "FETCH_LOAN_TYPES_REQUEST";
export const FETCH_LOAN_TYPES_SUCCESS = "FETCH_LOAN_TYPES_SUCCESS";
export const FETCH_LOAN_TYPES_ERROR = "FETCH_LOAN_TYPES_ERROR";
export const CREATE_LOAN_REQUEST = "CREATE_LOAN_REQUEST";
export const FETCH_LOAN_REQUEST = "FETCH_LOAN_REQUEST";
export const FETCH_LOAN_SUCCESS = "FETCH_LOAN_SUCCESS";
export const FETCH_LOAN_ERROR = "FETCH_LOAN_ERROR";
export const FETCH_LOAN_DISBURSEMENTS = "FETCH_LOAN_DISBURSEMENTS";
export const FETCH_LOAN_DISBURSEMENTS_REQUEST =
  "FETCH_LOAN_DISBURSEMENTS_REQUEST";
export const FETCH_LOAN_DISBURSEMENTS_ERROR = "FETCH_LOAN_DISBURSEMENTS_ERROR";
