import React, { useCallback, useEffect, useState } from "react";
import { Icon } from "@iconify/react";
import styled, { x } from "@xstyled/emotion";
import eyeOffOutline from "@iconify/icons-mdi/eye-off-outline";
import eyeOutline from "@iconify/icons-mdi/eye-outline";

import { Required } from "../../../../components/elements";
import { spacing } from "../../../../theme";
import { AddClientProps } from "../../interfaces";
import { useAddClient } from "../../hooks/useAddClient";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { fetchRoles } from "../../../../redux/roles/actionCreators";
import Dropdown from "../../../../components/elements/Drodown";
import { UserRoles } from "../../../../interfaces";

interface Props {
  handleClose: () => void;
}

const Wrapper = styled("div")`
  display: flex;
  flex-direction: column;
  margin-top: 1rem;
  justify-content: flex-start;
  width: 100%;
`;

const AddUser: React.FC<Props> = () => {
  const [formData, setFormData] = useState<AddClientProps>({
    email: "",
    password: "",
    username: "",
    role: "",
  });
  const [showPassword, setShowPassword] = useState<boolean>(false);

  const roles = useSelector((state: any) => state.roles);
  const dispatch = useDispatch();

  const navigate = useNavigate();

  const { addclient } = useAddClient(navigate);

  const toogleShowPassword = () => {
    setShowPassword((prev) => !prev);
  };

  // const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
  //   const { name, value } = e.target;

  //   setFormData((prev) => ({
  //     ...prev,
  //     [name]: value,
  //   }));
  // };

  const handleChange = (data: Partial<AddClientProps>) => {
    setFormData((prev) => ({ ...prev, ...data }));
  };

  const handleSelectRole = (option: UserRoles) => {
    handleChange({ role: option.name });
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    await addclient(formData);
  };

  const fetchRole = useCallback(() => {
    //@ts-expect-error
    dispatch(fetchRoles());
  }, [dispatch]);

  useEffect(() => {
    fetchRole();
  }, [fetchRole]);

  return (
    <x.div
      minH='40vh'
      mt={spacing[20]}
      display='flex'
      justifyContent='center'
      alignItems='center'
      w='100%'
    >
      <x.div maxWidth='35rem' w='100%'>
        <x.div
          borderRadius={spacing[4]}
          h='100%'
          bg='white'
          boxShadow='0px 4px 4px 0px rgba(130, 130, 130, 0.4), 0px 3px 5px 2px rgba(130, 130, 130, 0.4)'
          display='flex'
          mb='1rem'
          pb={spacing[4]}
          mx={{ _: spacing[2], md: 0 }}
        >
          <x.div px={spacing[4]} my={spacing[3]} w='100%'>
            <x.h3 fontSize='1.5rem' fontWeight='600' my={spacing[4]}>
              Add User
            </x.h3>
            <x.div>
              <x.small color='danger-600'>
                <x.i>
                  <Required /> indicates required field
                </x.i>
              </x.small>
            </x.div>
            <x.form onSubmit={handleSubmit}>
              <Wrapper>
                <x.label fontSize='1rem' fontWeight='600' mb={spacing[2]}>
                  Username <Required />
                </x.label>
                <x.input
                  w='100%'
                  name='username'
                  type='text'
                  outline='none'
                  border='1px solid'
                  p={spacing[2]}
                  borderColor='secondary-200'
                  borderRadius
                  placeholder='Username'
                  value={formData.username}
                  onChange={(e) => handleChange({ username: e.target.value })}
                />
              </Wrapper>
              <Wrapper>
                <x.label fontSize='1rem' fontWeight='600' mb={spacing[2]}>
                  Email <Required />
                </x.label>
                <x.input
                  w='100%'
                  name='email'
                  type='email'
                  outline='none'
                  border='1px solid'
                  p={spacing[2]}
                  borderColor='secondary-200'
                  borderRadius
                  placeholder='Email'
                  value={formData.email}
                  onChange={(e) => handleChange({ email: e.target.value })}
                />
              </Wrapper>
              <Wrapper>
                <x.label fontSize='1rem' fontWeight='600' mb={spacing[2]}>
                  Role <Required />
                </x.label>
                <Dropdown<UserRoles>
                  placeholder='Role'
                  options={roles.roles}
                  renderOption={(option) => (
                    <x.p py={2} mt={1} w='100%'>
                      {option?.name}
                    </x.p>
                  )}
                  optionToString={(option) => option?.name || ""}
                  isActiveOption={(option, selected) => {
                    if (option && selected) {
                      return option.id === selected.id;
                    }
                    return false;
                  }}
                  getSelectedOption={handleSelectRole}
                />
              </Wrapper>

              <x.div w='100%' position='relative'>
                <Wrapper>
                  <x.label fontSize='1rem' fontWeight='600' mb={spacing[2]}>
                    Password <Required />
                  </x.label>
                  <x.input
                    type={showPassword ? "text" : "password"}
                    name='password'
                    w='100%'
                    outline='none'
                    border='1px solid'
                    p={spacing[2]}
                    borderColor='secondary-200'
                    borderRadius
                    placeholder='Password'
                    value={formData.password}
                    onChange={(e) => handleChange({ password: e.target.value })}
                  />
                  {showPassword ? (
                    <StyledIcon
                      icon={eyeOffOutline}
                      onClick={toogleShowPassword}
                    />
                  ) : (
                    <StyledIcon
                      icon={eyeOutline}
                      onClick={toogleShowPassword}
                    />
                  )}
                </Wrapper>
              </x.div>

              <Wrapper>
                <x.button
                  borderRadius
                  border='1px solid'
                  borderColor='primary-main'
                  w='100%'
                  py={spacing[2]}
                  mt={spacing[4]}
                  bg={{ _: "primary-main", hover: "white" }}
                  transition='all 0.7s ease-in'
                  color={{ _: "white", hover: "primary-main" }}
                  fontSize='1rem'
                  fontWeight='600'
                  type='submit'
                  // onClick={handleClose}
                >
                  Add User
                </x.button>
              </Wrapper>
            </x.form>
          </x.div>
        </x.div>
      </x.div>
    </x.div>
  );
};

export const StyledIcon = styled(Icon)`
  position: absolute;
  top: 2.2rem;
  right: 1rem;
  font-size: 1.2rem;
  color: secondary-400;
  cursor: pointer;
`;

export default AddUser;
