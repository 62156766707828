import type { Column } from "react-table";
import { OnboardClientTableProps } from "../../interfaces";

export const onboardingClientTableColumns: Column<OnboardClientTableProps>[] = [
  {
    Header: "No",
    accessor: "no",
    //@ts-expect-error
    disableGlobalFilter: true,
  },
  {
    Header: "Name",
    accessor: "name",
  },
  {
    Header: "Email",
    accessor: "email",
  },
  {
    Header: "Phone number",
    accessor: "phone",
  },
  {
    Header: "ID number",
    accessor: "id_number",
    //@ts-expect-error
    disableGlobalFilter: true,
  },
  {
    Header: "Kra pin",
    accessor: "kra",
    //@ts-expect-error
    disableGlobalFilter: true,
  },

  {
    Header: "Business name",
    accessor: "business_name",
    //@ts-expect-error
    disableGlobalFilter: true,
  },

  {
    Header: "Business number",
    accessor: "business_number",
    //@ts-expect-error
    disableGlobalFilter: true,
  },
  {
    Header: "Branch",
    accessor: "branch",
    //@ts-expect-error
    disableGlobalFilter: true,
  },
  {
    Header: "Registration",
    accessor: "amount",
    //@ts-expect-error
    disableGlobalFilter: true,
  },
  {
    Header: "Actions",
    accessor: "id",
    //@ts-expect-error
    disableGlobalFilter: true,
  },
];
