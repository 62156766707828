import React from "react";

import ClientLoanRequest from "../../../modules/loans/clientloanrequest/elements";
import { useParams } from "react-router-dom";

interface Props {}

const ClientLoanRequestPage: React.FC<Props> = () => {
  const { id } = useParams();
  return (
    <>
      <ClientLoanRequest id={id}/>
    </>
  );
};

export default ClientLoanRequestPage;
