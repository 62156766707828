import React from "react";
import { Icon } from "@iconify/react";
import { x } from "@xstyled/emotion";
import sharpSearch from "@iconify/icons-ic/sharp-search";
import styled from "@emotion/styled";
import { spacing } from "../../theme";

interface Props {
  filterValue: string;
  setGlobalFilter: any;
  placeholder?: string;
}

const Search: React.FC<Props> = ({
  filterValue,
  setGlobalFilter,
  placeholder,
}) => {
  return (
    <x.div position='relative' w='100%' pr={spacing[5]} maxW='30rem'>
      <x.input
        w='100%'
        outline='none'
        border='1px solid'
        placeholder={placeholder}
        py={spacing[2]}
        px={spacing[4]}
        borderRadius={spacing[2]}
        value={filterValue}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
          setGlobalFilter(e.target.value)
        }
        borderColor='secondary-100'
      />
      <StyledIcon icon={sharpSearch} />
    </x.div>
  );
};

export const StyledIcon = styled(Icon)`
  position: absolute;
  top: 0.65rem;
  right: 1.6rem;
  font-size: 1.3rem;
  color: #d4d4d8ff;
`;

export default Search;
