import { GET } from "../../config";
import { getConfig } from "../../config/AxiosInstance";
import { UserRoles } from "../../interfaces";
import {
  FETCH_ROLES_ERROR,
  FETCH_ROLES_REQUEST,
  FETCH_ROLES_SUCCESS,
} from "./actionTypes";

const fetchRolesRequest = () => {
  return {
    type: FETCH_ROLES_REQUEST,
  };
};

const fetchRolesSuccess = (roles: UserRoles[]) => {
  return {
    type: FETCH_ROLES_SUCCESS,
    payload: roles,
  };
};

const fetchRolesError = (error: any) => {
  return {
    type: FETCH_ROLES_ERROR,
    payload: error,
  };
};

export const fetchRoles = () => {
  const config = getConfig();

  return async (dispatch: any) => {
    dispatch(fetchRolesRequest());

    try {
      const res = await GET("/authenticate/roles-list", config);

      const data = await res.data.roles.map((role: any) => ({
        name: role.name,
        id: role.id,
      }));

      dispatch(fetchRolesSuccess(data));
    } catch (error) {
      console.log("use role error", error);

      fetchRolesError(error);
    }
  };
};
