import { GET } from "../../../../../config";
import { getConfig } from "../../../../../config/AxiosInstance";

export const fecthRepaymentDetail = (id: any) => {
  const repayment = async () => {
    const config = getConfig();
    try {
      const res = await GET(`/paymentnotifications/${id}`, config);

      const data = await res.data;

      return data;
    } catch (error: any) {
      console.log("error", error.message);
    }
  };

  return {
    repayment,
  };
};
