import React from "react";
import { Provider } from "react-redux";
import { RouterProvider } from "react-router-dom";

import { router } from "./routes";
import store from "./redux/store";
import ToastLayout from "./components/layout/ToastLayout";

interface Props {}

const App: React.FC<Props> = () => {
  return (
    <ToastLayout>
      <Provider store={store}>
        <RouterProvider router={router} />
      </Provider>
    </ToastLayout>
  );
};

export default App;
