import React, {  useState } from "react";
import { x } from "@xstyled/emotion";
import { Icon } from "@iconify/react";
import addIcon from "@iconify/icons-material-symbols/add";
import { dashboardCardItems } from "../helpers/dashboardCardItems";
import { spacing } from "../../../theme";

import Modal from "../../../components/elements/Modal";
import AddUser from "../../signup/elements/adduser";

interface Props {}

const DashboardUI: React.FC<Props> = () => {
  const [isOpen, setIsOpen] = useState(false);

  
  const handleIsModalOpen = () => {
    setIsOpen((prev) => !prev);
  };

  const handleModalClose = () => {
    setIsOpen(false);
  };

  //useEffect(() => {
    //dispatch(fetchClients(navigate));
  //}, [dispatch, navigate]);
  //console.log("log", isOpen);

  return (
    <x.div
      minHeight='70vh'
      mt={spacing[5]}
      h='100%'
      // mx={{ _: spacing[2], md: spacing[4] }}
      // w={{ _: "100%", md: "77rem" }}

      // maxW='calc(100vw - 3rem)'
      // w='100%'
    >
      <x.div
        display='flex'
        alignItems='center'
        justifyContent='flex-end'
        mb={spacing[4]}
      >
        
        <x.div w='12rem' display='flex' justifyContent='flex-end'>
          <x.button
            py={spacing[2]}
            px={spacing[3]}
            border='1px solid'
            bg={{ _: "primary-main", hover: "primary-dark" }}
            color='white'
            borderColor='primary-main'
            transition='all 0.5s'
            borderRadius
            outline={{ _: "none", focus: "none" }}
            display='flex'
            alignItems='center'
            justifyContent='center'
            fontSize='1rem'
            w='100%'
            onClick={handleIsModalOpen}
          >
            <Icon
              icon={addIcon}
              style={{
                color: "white",
                fontSize: "1.2rem",
              }}
            />{" "}
            Add User
          </x.button>
          
        </x.div>
      </x.div>
      <x.div
        display='grid'
        gap={{ _: 2, md: 4 }}
        gridTemplateColumns={{ _: 1, md: 4 }}
      >
        {dashboardCardItems.map((item) => (
          <x.div
            key={item.id}
            maxWidth={{ xs: "19.5rem", sm: "22rem", md: "100%" }}
            bg={item.id === 3 ? "primary-main" : "white"}
            p={spacing[3]}
            borderRadius
            zIndex={100}
            maxH='10rem'
          >
            <x.h3
              fontWeight='600'
              fontSize='1.1rem'
              color={item.id === 3 ? "white" : "black"}
            >
              {`${item.currency} : ${item.amount}`}
            </x.h3>
            <x.div
              py={spacing[3]}
              display='flex'
              alignItems='center'
              justifyContent='space-between'
            >
              <x.p
                color={item.id === 3 ? "secondary-100" : "secondary-400"}
                textTransform='uppercase'
              >
                {item.title}
              </x.p>
              <x.div
                w='2.4rem'
                h='2.4rem'
                border='1px solid'
                borderColor='transparent'
                borderRadius='50%'
                bg='secondary-100'
                display='flex'
                justifyContent='center'
                alignItems='center'
                fontSize='1.6rem'
                color={item.id === 3 ? "secondary-400" : "primary-light"}
              >
                <Icon icon={item.icon} />
              </x.div>
            </x.div>
            <x.div
              mt={spacing[6]}
              display='flex'
              alignItems='center'
              justifyContent={"flex-start"}
              fontSize='0.9rem'
            >
              <x.span
                bg={
                  item.id === 1 || item.id === 4 ? "primary-main" : "danger-100"
                }
                px={spacing[3]}
                py='0.5rem'
                border='1px solid'
                borderColor='transparent'
                borderRadius
                color={item.id === 1 || item.id === 4 ? "white" : "danger-400"}
                fontWeight='600'
              >
                {item.total}
              </x.span>
              <x.p
                px={spacing[2]}
                color={item.id === 3 ? "secondary-100" : "secondary-400"}
              >
                {item.content}
              </x.p>
            </x.div>
          </x.div>
        ))}
      </x.div>
      
      {isOpen ? (
        <Modal variant='md' open={isOpen} onClose={() => setIsOpen(false)}>
          <x.div minH='60vh'>
            <AddUser handleClose={handleModalClose} />
          </x.div>
        </Modal>
      ) : null}
    </x.div>
  );
};

export default DashboardUI;
