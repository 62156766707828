import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { x } from "@xstyled/emotion";
import { Icon } from "@iconify/react";
import twotoneMenuOpen from "@iconify/icons-ic/twotone-menu-open";
import sharpSpaceDashboard from "@iconify/icons-ic/sharp-space-dashboard";
import fileInvoiceDollar from "@iconify/icons-fa6-solid/file-invoice-dollar";
import menuIcon from "@iconify/icons-carbon/menu";
import cardAccountDetails from "@iconify/icons-mdi/card-account-details";
import userGroup from "@iconify/icons-mdi/user-group";
import viewListOutline from "@iconify/icons-mdi/view-list-outline";
import account from "@iconify/icons-mdi/account";
import cogs from "@iconify/icons-mdi/cogs";
import attachFile from "@iconify/icons-material-symbols/attach-file";
import logoutBoxRLine from "@iconify/icons-ri/logout-box-r-line";

import { SidebarMenuItem } from "../elements";
import { spacing } from "../../theme";
import { urlTabs } from "../../enums";
import { getUsername } from "../../helpers";

interface Props {}

type PathProps =
  | "dashboard"
  | "login"
  | "register"
  | "loans"
  | "client"
  | "batchfiles"
  | "loanRepayments"
  | "reports"
  | "users"
  | "profile"
  | "settings";

const Sidebar: React.FC<Props> = () => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);

  const location = useLocation();

  const path: string | undefined = location.pathname.split("/")[1];
  const isIndex: boolean = !path;
  const activTab = isIndex ? "dashboard" : (path as PathProps);

  (async ()=>{
    if(await getUsername() === null){
      setIsAuthenticated(false);
    }else{
      setIsAuthenticated(true);
    }
  })();

  

  const toogleNavbar = () => {
    setIsOpen((prev) => !prev);
  };

  return (
    <x.div
      top='0'
      minWidth={isOpen ? "12rem" : "3rem"}
      bg='white'
      boxShadow='0px 2px 2px 0px rgba(130, 130, 130, 0.4), 0px 2px 3px 1px rgba(130, 130, 130, 0.4)'
      h='calc(100vh - 5rem)'
      zIndex={100}
      display='flex'
      flexDirection='column'
      w={isOpen ? "12rem" : "3rem"}
      transition='all 0.6s'
      position='sticky'
      px={spacing[2]}
      pt={spacing[3]}
    >
      <x.div
        fontSize='2rem'
        transition='all 0.3s'
        display='flex'
        justifyContent={isOpen ? "flex-end" : "center"}
        cursor='pointer'
        color='primary-main'
        border='1px solid'
        borderRadius
        px={isOpen ? "0.5rem" : "0.1rem"}
        py={isOpen ? "0.1rem" : "0.1rem"}
        borderColor={isOpen ? "transparent" : "primary-main"}
        mb={spacing[4]}
      >
        {isOpen ? (
          <Icon icon={twotoneMenuOpen} onClick={toogleNavbar} />
        ) : (
          <Icon icon={menuIcon} onClick={toogleNavbar} />
        )}
      </x.div>
      {isAuthenticated ? (
      <x.div>
      <Link to='/dashboard'>
        <SidebarMenuItem
          title='Dashboard'
          open={isOpen}
          active={activTab === urlTabs.dashboard}
          icon={sharpSpaceDashboard}
        >
          Dashboard
        </SidebarMenuItem>
      </Link>
      <Link to='/client'>
        <SidebarMenuItem
          title='Clients'
          open={isOpen}
          active={activTab === urlTabs.client}
          icon={cardAccountDetails}
        >
          Clients
        </SidebarMenuItem>
      </Link>
      <Link to='/loans'>
        <SidebarMenuItem
          title='Loans'
          open={isOpen}
          active={activTab === urlTabs.loans}
          icon={account}
        >
          Loans
        </SidebarMenuItem>
      </Link>
      <Link to='/repayments'>
        <SidebarMenuItem
          title='Loan Repayments'
          open={isOpen}
          active={activTab === urlTabs.batchfiles}
          icon={attachFile}
        >
          Loan Repayments
        </SidebarMenuItem>
      </Link>
      <Link to='/reports'>
        <SidebarMenuItem
          title='Reports'
          open={isOpen}
          active={activTab === urlTabs.reports}
          icon={viewListOutline}
        >
          Reports
        </SidebarMenuItem>
      </Link>
      <Link to='/users'>
        <SidebarMenuItem
          title='Users'
          open={isOpen}
          active={activTab === urlTabs.users}
          icon={userGroup}
        >
          Users
        </SidebarMenuItem>
      </Link>
      
      <Link to='/settings'>
        <SidebarMenuItem
          title='Settings'
          open={isOpen}
          active={activTab === urlTabs.settings}
          icon={cogs}
        >
          Settings
        </SidebarMenuItem>
      </Link>
      <x.button
          display='flex'
          justifyContent='center'
          alignItems='center'
          border='1px solid'
          borderColor='primary-main'
          color='primary-main'
          transition='all 0.3s'
          fontWeight='500'
          px={isOpen ? "0.6rem" : "0.1rem"}
          py={isOpen ? "0.6rem" : "0.1rem"}
          my={spacing[4]}
          borderRadius
          w='100%'
          minWidth='100%'
          bg='white'
          ml='0.2rem'
          title='Logout'
        >
          <Icon
            style={{
              fontSize: isOpen ? "1.6rem" : "1.8rem",
            }}
            icon={logoutBoxRLine}
            onClick={toogleNavbar}
          />
        </x.button>    
      </x.div>
      ): (
        <x.div>
      <Link to='/login'>
        <SidebarMenuItem
          title='Login'
          open={isOpen}
          active={activTab === urlTabs.login}
          icon={account}
        >
          Login
        </SidebarMenuItem>
      </Link>
      
           
      </x.div>
      )}
    </x.div>
    
  );
};

export default Sidebar;
