import { Column } from "react-table";
import { LoanDisbursement } from "../interfaces";

export const loanDisbursementTableColumns: Column<LoanDisbursement>[] = [
  {
    Header: "No",
    accessor: "no",
    //@ts-expect-error
    disableGlobalFilter: true,
  },
  {
    Header: "Loan amount",
    accessor: "amount",
    //@ts-expect-error
    disableGlobalFilter: true,
  },
  {
    Header: "Processing Fees",
    accessor: "processing_fees",
    //@ts-expect-error
    disableGlobalFilter: true,
  },
  {
    Header: "Status",
    accessor: "status",
  },
  {
    Header: "CSO",
    accessor: "cso",
    //@ts-expect-error
    disableGlobalFilter: true,
  },
  // {
  //   Header: "Actions",
  //   accessor: "id",
  //   //@ts-expect-error
  //   disableGlobalFilter: true,
  // },
];
