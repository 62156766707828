import {
  CREATE_LOAN_REQUEST,
  FETCH_LOAN_DISBURSEMENTS,
  FETCH_LOAN_DISBURSEMENTS_ERROR,
  FETCH_LOAN_DISBURSEMENTS_REQUEST,
  FETCH_LOAN_ERROR,
  FETCH_LOAN_REQUEST,
  FETCH_LOAN_SUCCESS,
  FETCH_LOAN_TYPES_ERROR,
  FETCH_LOAN_TYPES_REQUEST,
  FETCH_LOAN_TYPES_SUCCESS,
} from "./actionType";

import type { ClientLoan, LoanTypes } from "../../interfaces";

interface ILoanType {
  loading: boolean;
  loantypes: LoanTypes[];
  error: any;
}

interface IClientLoan {
  loading: boolean;
  loanrequests: ClientLoan[];
  error: any;
}

const initialLoanType: ILoanType = {
  loading: false,
  loantypes: [],
  error: "",
};

const initialLoanState: IClientLoan = {
  error: "",
  loading: false,
  loanrequests: [],
};

const initialLoanDisbursement = {
  error: "",
  loading: false,
  loans: [],
};

export const loanTypesReducer = (state = initialLoanType, action: any) => {
  switch (action.type) {
    case FETCH_LOAN_TYPES_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case FETCH_LOAN_TYPES_SUCCESS:
      return {
        loading: false,
        loantypes: action.payload,
        error: "",
      };
    case FETCH_LOAN_TYPES_ERROR:
      return {
        loading: false,
        loantypes: [],
        error: action.payload,
      };
    default:
      return state;
  }
};

export const clientLoanRequestReducer = (
  state = initialLoanState,
  action: any
) => {
  switch (action.type) {
    case FETCH_LOAN_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case FETCH_LOAN_SUCCESS:
      return {
        loading: false,
        loanrequests: action.payload,
        error: "",
      };
    case CREATE_LOAN_REQUEST:
      return {
        loading: false,
        loansrequests: [action.payload, ...state.loanrequests],
        error: "",
      };
    case FETCH_LOAN_ERROR:
      return {
        loanrequests: false,
        loans: [],
        error: action.payload,
      };
    default:
      return state;
  }
};

export const loanDisbursmentReducer = (
  state = initialLoanDisbursement,
  action: any
) => {
  switch (action.type) {
    case FETCH_LOAN_DISBURSEMENTS_REQUEST:
      return {
        ...state,
        laoding: true,
      };
    case FETCH_LOAN_DISBURSEMENTS:
      return {
        loading: false,
        loans: action.payload,
        error: "",
      };
    case FETCH_LOAN_DISBURSEMENTS_ERROR:
      return {
        loading: false,
        loans: [],
        error: action.payload,
      };

    default:
      return state;
  }
};

