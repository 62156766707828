import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useGlobalFilter, usePagination, useTable } from "react-table";
import { Link, useNavigate } from "react-router-dom";
import { Icon } from "@iconify/react";
import { x } from "@xstyled/emotion";
import nextLtr from "@iconify/icons-ooui/next-ltr";
import nextRtl from "@iconify/icons-ooui/next-rtl";

import Dropdown from "../../../components/elements/Drodown";
import Search from "../../../components/elements/Search";
import { fetchClients } from "../../../redux/clients/actionCreators";
import { capitalize, formatCurrency, formatTime } from "../../dashboard/helpers";
import { RepaymentProps } from "../interfaces";
import { ClientTableEnums } from "../../../enums";
import { spacing } from "../../../theme";
import { repaymentsTableColumns } from "./helpers/repaymentsTableColumns";

interface Props {}

const RepaymentsUI: React.FC<Props> = () => {

  const repaymentsData = useSelector((state: any) => state.clients);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [pageCount, setPageCount] = useState(0); 

  const repayments: RepaymentProps[] = repaymentsData.clients.map(
    (client: any, index: number) => ({
      no: index + 1,
      id: client.id,
      name: capitalize(
        `${client.firstName} ${client.middleName} ${client.lastName}`
      ),
      bill_ref_no: client.billRefNumber,
      business_code: client.businessShortCode,
      invoice_no: client.invoiceNumber,
      msisdn: client.msisdn,
      org_bal: formatCurrency(client.orgAccountBalance),
      transaction_id: client.transID,
      third_party_transaction_id: client.thirdPartyTransID,
      transaction_amt: formatCurrency(client.transAmount),
      transaction_type: client.transactionType,
      transaction_time: formatTime(client.transTime),
      // transaction_time: 1,
    })
  );

  const data = useMemo(() => repayments, [repayments]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    //@ts-ignore
    page,
    //@ts-ignore
    nextPage,
    //@ts-ignore
    previousPage,
    //@ts-ignore
    canNextPage,
    //@ts-ignore
    canPreviousPage,
    //@ts-ignore
    pageOptions,
    //@ts-ignore
    state,
    //@ts-ignore
    gotoPage,
    //@ts-ignore
    setPageSize,
    prepareRow,
    allColumns,
    //@ts-ignore
    setGlobalFilter,
  } = useTable(
    {
      columns: repaymentsTableColumns,
      data,
      autoResetGlobalFilter: false,
      //@ts-ignore
      autoResetPage: false,
      //@ts-ignore
      initialState: { pageIndex: 0 },
      manualPagination: true, // Indicate server-side pagination
      pageCount: pageCount, // Determined by the server 
    },
    useGlobalFilter,
    usePagination
  );

  // @ts-ignore
  const { pageIndex, pageSize, globalFilter } = state;

  useEffect(() => {
    setLoading(true);
    // @ts-expect-error ts(2345): Argument of type '(dispatch: any) => Promise<void>' is not assignable to parameter of type 'AnyAction'.
    dispatch(fetchClients(navigate, pageSize, pageIndex));
    setLoading(false);
  }, [dispatch, navigate, pageSize, pageIndex]);

  return (
    <x.div w='100%'>
      <x.div display='flex' alignItems='center' justifyContent='space-between'>
        <x.h3 fontSize='1.3rem' fontWeight='600' m={spacing[2]}>
          Client Loan Repayments
        </x.h3>
        <Link to='/repayments/print'>
          <x.button
            outline={{ _: "none", focus: "none" }}
            border='1px solid'
            borderColor='transparent'
            bg={{ _: "primary-main", hover: "primary-dark" }}
            color='white'
            px={spacing[4]}
            py={spacing[2]}
            borderRadius
            mr={spacing[4]}
          >
            Print
          </x.button>
        </Link>
      </x.div>

      <x.div
        bg='white'
        py={spacing[4]}
        px={spacing[4]}
        my={spacing[4]}
        borderRadius={spacing[2]}
      >
        <x.div
          display='flex'
          justifyContent='space-between'
          alignItems='center'
          my={spacing[2]}
        >
          <Search
            placeholder='Search repayment by client or email or phone'
            filterValue={globalFilter}
            setGlobalFilter={setGlobalFilter}
          />
          <x.div minWidth='11.25rem' py='0' w={{ sm: "auto", xs: "100%" }}>
            <Dropdown
              showSelectedOption={false}
              keepDropdownOpen
              mt={0}
              options={[null, ...allColumns]}
              renderOption={(column) => (
                <x.label key={column?.id} py={spacing[2]} display='flex'>
                  {!column ? (
                    <>Toggle columns</>
                  ) : (
                    <>
                      <x.input
                        mr={spacing[1]}
                        {...column?.getToggleHiddenProps()}
                        type='checkbox'
                      />{" "}
                      {column?.Header}
                    </>
                  )}
                </x.label>
              )}
              defaultOption={null}
              placeholder='Toggle columns'
              optionToString={(option) => option?.Header?.toString() || ""}
            />
          </x.div>
        </x.div>
        <x.div overflowX='auto' w='100%'>
          <x.table
            {...getTableProps()}
            borderCollapse='collapse'
            my={spacing[4]}
            mx={0}
            fontSize='0.9rem'
            w='100%'
            role='table'
            aria-label='Client repayments table'
          >
            <x.thead w='100%'>
              {headerGroups.map((headerGroup, headerGroupIndex) => (
                <x.tr
                  {...headerGroup.getHeaderGroupProps()}
                  key={headerGroupIndex}
                  textAlign='left'
                >
                  {headerGroup.headers.map((column) => (
                    <x.th
                      {...column.getHeaderProps()}
                      key={column.id}
                      px={spacing[2]}
                      pb={spacing[3]}
                      whiteSpace='nowrap'
                    >
                      {column.render("Header")}
                    </x.th>
                  ))}
                </x.tr>
              ))}
            </x.thead>
            <x.tbody {...getTableBodyProps} w='100%'>
              {page.length ? (
                page.map((row: any, rowIndex: any) => {
                  prepareRow(row);
                  return (
                    <x.tr
                      {...row.getRowProps()}
                      key={row.id}
                      bg={rowIndex % 2 === 0 ? "gray-100" : "white"}
                    >
                      {row.cells.map((cell: any) => (
                        <x.td
                          {...cell.getCellProps()}
                          key={cell.column.id}
                          textAlign='left'
                          px={spacing[2]}
                          borderTop={rowIndex !== 0 && "1px solid"}
                          borderColor='secondary-200'
                          py={spacing[2]}
                        >
                          {cell.column.Header === ClientTableEnums.ACTIONS ? (
                            <x.div display='flex' alignItems='center'>
                              
                              <Link to={`/repayments/${cell.value}`}>
                                <x.button
                                  border='1px solid'
                                  h='2rem'
                                  borderColor='primary-main'
                                  mx={spacing[1]}
                                  color='white'
                                  bg='primary-main'
                                  borderRadius={spacing[1]}
                                  px={spacing[2]}
                                  title='View'
                                >
                                  View
                                </x.button>
                              </Link>
                              
                            </x.div>
                          ) : (
                            <x.p py={spacing[3]} whiteSpace='nowrap'>
                              {cell.render("Cell")}
                            </x.p>
                          )}
                        </x.td>
                      ))}
                    </x.tr>
                  );
                })
              ) : (
                <x.tr>
                  <x.td colSpan={allColumns.length}>
                    <x.div
                      minHeight='6.25rem'
                      display='flex'
                      justifyContent='center'
                      alignItems='center'
                    >
                      No available loan repayments to display
                    </x.div>
                  </x.td>
                </x.tr>
              )}
            </x.tbody>
          </x.table>
          <x.div
            display='flex'
            justifyContent='space-between'
            alignContent='center'
            borderTop='1px solid'
            w='full'
            borderColor='secondary-100'
          >
            <x.div py={spacing[2]}>
              <x.button
                my={spacing[1]}
                bg='transparent'
                border='0px solid'
                textAlign='center'
                borderColor='primary-main'
                color='#9CA3AF'
                pr={spacing[3]}
                py={spacing[1]}
                transition='all 0.3s'
                fontSize='14px'
              >
                <x.span>
                  {"Showing page "}
                  {pageIndex + 1}
                  {" of "}
                  {pageOptions.length}{" "}
                </x.span>
              </x.button>
            </x.div>
            <x.div
              display='flex'
              alignContent='center'
              py={spacing[4]}
              transition='all 0.3sec'
            >
              {canPreviousPage ? (
                <>
                  <x.button
                    bg={{ _: "transparent", hover: "transparent" }}
                    color='#9CA3AF'
                    border='0px solid'
                    borderColor={{ _: "transparent", focus: "transparent" }}
                    outline={{ _: "none", focus: "none" }}
                    px={spacing[0]}
                    pt={spacing[2]}
                    pb={spacing[1]}
                    fontSize='0.8rem'
                    onClick={() => gotoPage(0)}
                    display='flex'
                    mx={spacing[1]}
                  >
                    <Icon icon='ooui:double-chevron-end-rtl' />
                  </x.button>
                  <x.button
                    bg={{ _: "transparent", hover: "transparent" }}
                    color='#9CA3AF'
                    border='0px solid'
                    borderColor={{ _: "transparent", focus: "transparent" }}
                    outline={{ _: "none", focus: "none" }}
                    px={spacing[0]}
                    py={spacing[1]}
                    fontSize='0.8rem'
                    onClick={() => previousPage()}
                    mx={spacing[1]}
                  >
                    <Icon icon={nextRtl} />
                  </x.button>
                </>
              ) : null}

              {pageCount < 6 &&
                Array.from(Array(pageCount).keys()).map((x, i) => (
                  <button
                    style={{
                      backgroundColor: pageIndex === i ? "#86198fff" : "",
                      color: pageIndex === i ? "white" : "#9CA3AF",
                      borderRadius: pageIndex === i ? "50%" : "",
                      border: "0px solid #0052cc",
                      padding: "0px 6px",
                      outline: "none",
                      fontSize: "0.8rem",
                      minWidth: "28px",
                      height: "28px",
                      margin: "0 3px",
                    }}
                    key={i}
                    onClick={() => {
                      gotoPage(i);
                    }}
                  >
                    {i + 1}
                  </button>
                ))}

              {canNextPage ? (
                <>
                  <x.button
                    bg={{ _: "transparent", hover: "transparent" }}
                    color='#9CA3AF'
                    border='0px solid'
                    borderColor={{ _: "transparent", focus: "transparent" }}
                    outline={{ _: "none", focus: "none" }}
                    px={spacing[0]}
                    py={spacing[1]}
                    fontSize='0.8rem'
                    onClick={() => nextPage()}
                    mx={spacing[1]}
                  >
                    <Icon icon={nextLtr} />
                  </x.button>
                  <x.button
                    bg={{ _: "transparent", hover: "transparent" }}
                    color='#9CA3AF'
                    border='0px solid'
                    borderColor={{ _: "transparent", focus: "transparent" }}
                    outline={{ _: "none", focus: "none" }}
                    px={spacing[0]}
                    py={spacing[1]}
                    fontSize='0.8rem'
                    onClick={() => gotoPage(pageCount - 1)}
                    display='flex'
                    alignItems='center'
                    mx={spacing[1]}
                  >
                    <Icon icon='ooui:double-chevron-start-rtl' />
                  </x.button>
                </>
              ) : null}
            </x.div>
          </x.div>
        </x.div>
      </x.div>

    </x.div>
  );
};

export default RepaymentsUI;
