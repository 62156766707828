import React from "react";
import { useParams } from "react-router-dom";
import ClientDetailModule from "../../modules/clients/elements/clientdetail";

interface Props {}

const ClentDetailPage: React.FC<Props> = () => {
  const { id } = useParams();

  return (
    <>
      <ClientDetailModule id={id} />
    </>
  );
};

export default ClentDetailPage;
