import { GET } from "../config";
import { getConfig } from "../config/AxiosInstance";
import { user_enums } from "../enums";

export const getRole = (role: string) => {
  if (!role) return "";

  return `${role.charAt(0).toUpperCase()}${role.slice(1).toLowerCase()}`;
};

export const setUserLocalStorage = (key: string, item: any) => {
  localStorage.setItem(key, JSON.stringify(item));
};

export const getUserLocalStorage = (key: string) => {
  const item = localStorage.getItem(key);
  if (item !== null) {
    return JSON.parse(item);
  }
  return null;
};

export const removeUserLocalStorage = (key: string) => {
  localStorage.removeItem(key);
};

export const getToken = () => {
  const user = getUserLocalStorage(user_enums.user);

  if (user) {
    const token = user;
    return token;
  }
  return null;
};

export const getUsername = async () => {
  const config = getConfig();
  try {
    var resp = await GET("/authenticate/user", config
    );
    if (resp.status === 200) {
        return resp.data;
    }
    else if (resp.status === 401) {
      console.log("User not logged in");
    }
  }
  catch (e) {
    console.log("Error checking user status");
  }
  return null;
};

export const formatDateTime = (dateTimeStr: any) => {
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const date = new Date(dateTimeStr);
  const day = date.getDate();
  const month = months[date.getMonth()];
  const year = date.getFullYear();

  // Get hours and minutes in 12-hour format
  let hours = date.getHours();
  let minutes = date.getMinutes();
  const ampm = hours >= 12 ? "pm" : "am";
  hours = hours % 12 || 12; // Convert to 12-hour format
  //@ts-expect-error
  minutes = minutes.toString().padStart(2, "0"); // Ensure two digits for minutes

  const formattedDateTime = `${day} ${month}, ${year} - ${hours}:${minutes} ${ampm}`;
  return formattedDateTime;
};
