import { GET } from "../../../config";
import { getConfig } from "../../../config/AxiosInstance";
import {
  FETCH_CLIENT_LOAN_HISTORY_ERROR,
  FETCH_CLIENT_LOAN_HISTORY_REQUEST,
  FETCH_CLIENT_LOAN_HISTORY_SUCCESS,
} from "./actionTypes";
import { ClientLoan } from "../../../interfaces";

const fetchClientLoanHistoryRequest = () => {
  return {
    type: FETCH_CLIENT_LOAN_HISTORY_REQUEST,
  };
};

const fetchClientLoanHistorySuccess = (clientLoans: any) => {
  return {
    type: FETCH_CLIENT_LOAN_HISTORY_SUCCESS,
    payload: clientLoans,
  };
};

const fetchClientLoanHistoryError = (error: any) => {
  return {
    type: FETCH_CLIENT_LOAN_HISTORY_ERROR,
    payload: error,
  };
};

export const fetchClientLoanHistory = (id: any) => {
  const config = getConfig();
  return async (dispatch: any) => {
    dispatch(fetchClientLoanHistoryRequest());

    try {
      const res = await GET(`/loanrequest/byclient/${id}`, config);

      const data = await res.data;
      console.log(data);
      const clientLoans: ClientLoan[] = data.map((clientLoan: any) => ({
        client: clientLoan.client,
        loantype: clientLoan.loanType.type,
        status: clientLoan.loanRequestStatus.status,
        processingfees: clientLoan.processingFeesPaid,
        cso: clientLoan.username,
        appliation_date: clientLoan.createdOn,
        loan_amt: clientLoan.loanAmount,
        id: clientLoan.id,
      }));
      dispatch(fetchClientLoanHistorySuccess(clientLoans));
    } catch (error: any) {
      console.log("error", error);
      dispatch(fetchClientLoanHistoryError(error));
    }
  };
};
