import React from "react";
import { useParams } from "react-router-dom";

import EditClientLoanRequest from "../../../modules/loans/clientloanrequest/elements/editclientloanrequest";

interface Props {}

const EditClientLoanRequestPage: React.FC<Props> = () => {
  const { id } = useParams();

  return (
    <>
      <EditClientLoanRequest id={id} />
    </>
  );
};

export default EditClientLoanRequestPage;
