import React from "react";
import { x } from "@xstyled/emotion";

import type { SystemProps } from "@xstyled/emotion";

interface Props extends Omit<React.HTMLAttributes<HTMLSpanElement>, "color"> {}
interface Props extends SystemProps {}

const Required: React.FC<Props> = (props) => (
  <x.span color='danger-500' {...props}>
    {" * "}
  </x.span>
);

export default Required;
