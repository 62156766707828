import { defaultTheme } from "@xstyled/emotion";

export const spacing = {
  px: "1px",
  0: "0",
  0.5: "0.125rem",
  1: "0.25rem",
  1.5: "0.375rem",
  2: "0.5rem",
  2.5: "0.625rem",
  3: "0.75rem",
  3.5: "0.875rem",
  4: "1rem",
  5: "1.25rem",
  6: "1.5rem",
  7: "1.75rem",
  7.5: "1.875rem",
  8: "2rem",
  9: "2.25rem",
  10: "2.5rem",
  11: "2.75rem",
  12: "3rem",
  14: "3.5rem",
  16: "4rem",
  17: "4.25rem",
  17.5: "4.375rem",
  18: "4.5rem",
  20: "5rem",
  24: "6rem",
  24.5: "6.25rem",
  28: "7rem",
  29: "7.25rem",
  30: "7.5rem",
  32: "8rem",
  36: "9rem",
  40: "10rem",
  44: "11rem",
  48: "12rem",
  50: "12.5rem",
  52: "13rem",
  54: "13.75rem",
  56: "14rem",
  60: "15rem",
  64: "16rem",
  72: "18rem",
  80: "20rem",
  96: "24rem",
};

export const colorTheme = {
  "primary-dark": "#701a75ff",
  "primary-main": "#86198fff",
  "primary-light": "#a21cafff",
  "primary-main-50": "#d946efff",
  "primary-main-100": "#e879f9ff",
  "danger-50": defaultTheme.colors["red-50"],
  "danger-100": defaultTheme.colors["red-100"],
  "danger-200": defaultTheme.colors["red-200"],
  "danger-300": defaultTheme.colors["red-300"],
  "danger-400": defaultTheme.colors["red-400"],
  "danger-500": defaultTheme.colors["red-500"],
  "danger-600": defaultTheme.colors["red-600"],
  "danger-700": defaultTheme.colors["red-700"],
  "danger-800": defaultTheme.colors["red-800"],
  "danger-900": defaultTheme.colors["red-900"],
  "success-50": defaultTheme.colors["green-50"],
  "success-100": defaultTheme.colors["green-100"],
  "success-200": defaultTheme.colors["green-200"],
  "success-300": defaultTheme.colors["green-300"],
  "success-400": defaultTheme.colors["green-400"],
  "success-500": defaultTheme.colors["green-500"],
  "success-600": defaultTheme.colors["green-600"],
  "success-700": defaultTheme.colors["green-700"],
  "success-800": defaultTheme.colors["green-800"],
  "success-900": defaultTheme.colors["green-900"],
  "secondary-50": defaultTheme.colors["gray-50"],
  "secondary-100": defaultTheme.colors["gray-100"],
  "secondary-200": defaultTheme.colors["gray-200"],
  "secondary-300": defaultTheme.colors["gray-300"],
  "secondary-400": defaultTheme.colors["gray-400"],
  "secondary-500": defaultTheme.colors["gray-500"],
  "secondary-600": defaultTheme.colors["gray-600"],
  "secondary-700": defaultTheme.colors["gray-700"],
  "secondary-800": defaultTheme.colors["gray-800"],
  "secondary-900": defaultTheme.colors["gray-900"],
};
