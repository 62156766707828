import { GET } from "../../../../config";

export const fetchLoanRequestDetail = (id: any) => {
  const loanRequest = async () => {
    try {
      const res = await GET(`/LoanRequest/${id}`);

      const data = await res.data;

      return data;
    } catch (error: any) {
      console.log("error", error.message);
    }
  };

  return {
    loanRequest,
  };
};
