import React from "react";
import { x } from "@xstyled/emotion";
import { Icon } from "@iconify/react";

import { spacing } from "../../theme";

import type { IconifyIcon } from "@iconify/react";

interface Props {
  icon: IconifyIcon;
  children: React.ReactNode;
  active: boolean;
  open: boolean;
  title: string;
}

const SidebarMenuItem: React.FC<Props> = ({
  icon,
  active,
  children,
  open = false,
  title,
}) => {
  return (
    <x.span
      display='flex'
      w='100%'
      justifyContent='center'
      alignItems='center'
      border='1px solid'
      color={{ _: active ? "white" : "primary-main", hover: "white" }}
      bg={{
        _: active ? "primary-main" : "white",
        hover: active ? "primary-dark" : "primary-main",
      }}
      transition='all 0.3s'
      fontWeight='500'
      px={open ? "0.5rem" : "0.15rem"}
      py={open ? "0.6rem" : "0.1rem"}
      my={spacing[4]}
      borderRadius
      fontSize={open ? "1.4rem" : "2rem"}
      title={title}
    >
      <Icon icon={icon} />
      <x.span px='0.4rem' fontSize='1rem' display={open ? "block" : "none"}>
        {children}
      </x.span>
    </x.span>
  );
};

export default SidebarMenuItem;
