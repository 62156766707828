import React from "react";

import LoanDisbursementModule from "../../../modules/loans/loandisbursements";

interface Props {}

const LoanDisbursementPage: React.FC<Props> = () => {
  return (
    <>
      <LoanDisbursementModule />
    </>
  );
};

export default LoanDisbursementPage;
