import type { Column } from "react-table";

import { ClientLoan } from "../../../../interfaces";

export const clientLoanRequestTableColumns: Column<ClientLoan>[] = [
  {
    Header: "No",
    accessor: "no",
    //@ts-expect-error
    disableGlobalFilter: true,
  },
  {
    Header: "Loan amount",
    accessor: "loan_amt",
    //@ts-expect-error
    disableGlobalFilter: true,
  },
  {
    Header: "Processing Fees",
    accessor: "processingfees",
    //@ts-expect-error
    disableGlobalFilter: true,
  },
  {
    Header: "Loan Type",
    accessor: "loantype",
  },

  {
    Header: "Application Date",
    accessor: "appliation_date",
    //@ts-expect-error
    disableGlobalFilter: true,
  },

  {
    Header: "CSO",
    accessor: "cso",
    //@ts-expect-error
    disableGlobalFilter: true,
  },
  {
    Header: "Actions",
    accessor: "id",
    //@ts-expect-error
    disableGlobalFilter: true,
  },
];
