export const capitalize = (word: string) => {
  const words = word.split(" ");

  const capitalizedWords = words.map((word) => {
    const firstLetter = word.charAt(0).toUpperCase();
    const restOfWord = word.slice(1).toLowerCase();
    return firstLetter + restOfWord;
  });

  const capitalizedString = capitalizedWords.join(" ");

  return capitalizedString;
};

export const formatTime = (dateString?: any) => {
  const year = dateString.substr(0, 4);
  const month = dateString.substr(4, 2);
  const day = dateString.substr(6, 2);
  const hour = dateString.substr(8, 2);
  const minute = dateString.substr(10, 2);
  const second = dateString.substr(12, 2);

  const formattedDate = `${day}/${month}/${year}, ${hour}:${minute}:${second} AM`;

  return formattedDate;
};

export const formatCurrency = (number: any) => {
  const roundedNumber = Number(number).toFixed(2);

  const parts = roundedNumber.toString().split(".");
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");

  return parts.join(".");
};
