export enum urlTabs {
  login = "login",
  register = "register",
  dashboard = "dashboard",
  loans = "loans",
  client = "client",
  batchfiles = "batchfiles",
  reports = "reports",
  users = "users",
  settings = "settings",
  profile = "profile",
}

export enum RolesEnum {
  USER = "USER",
  ADMIN = "ADMIN",
  OPERATIONSMANAGER = "OPERATIONSMANAGER",
}

export enum ClientTableEnums {
  ACTIONS = "Actions",
}

export const user_enums = {
  user: "user",
  cso: "cso",
  relationshipOfficer: "relationshipOfficer",
};
