import React from "react";
import ClientsUI from "../../modules/clients/elements";

interface Props {}

const ClientsPage: React.FC<Props> = () => {
  return (
    <>
      <ClientsUI />
    </>
  );
};

export default ClientsPage;
