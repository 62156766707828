import React from "react";

import DashboardUI from "../../modules/dashboard/elements";

interface Props {}

const DashboardPage: React.FC<Props> = () => {
  return (
    <>
      <DashboardUI />
    </>
  );
};

export default DashboardPage;
