import { toast } from "react-toastify";
import { GET } from "../../../../config";
import { getConfig } from "../../../../config/AxiosInstance";

export const approveLoanRequest = async (id: any) => {
  const config = getConfig();
  try {
    const res = await GET(`loanrequest/approve/${id}`, config);

    const data = await res.data;

    const status = await data.loanRequestStatus.status;

    toast.success(`Status: ${status}`);
  } catch (error: any) {}
};

export const disburseLoanRequest = async (id: any) => {
  const config = getConfig();
  try {
    const res = await GET(`LoanDisbursement/${id}`, config);

    const data = await res.data;

    toast.success("Loan disbursed successfully");

    console.log("disburse", data);
  } catch (error: any) {
    console.log("errr", error.response.data.message);
    toast.error(error.response.data.message);
  }
};
