import { GET } from "../../../../../config";
import { getConfig } from "../../../../../config/AxiosInstance";
import { Client } from "../../../../../interfaces";
import {
  FETCH_CLIENT_ERROR,
  FETCH_CLIENT_SUCCESS,
  FETCH_CLIENT_REQUEST,
} from "../../../../../redux/clients/actionTypes";



const fetchClient = () => {
  return {
    type: FETCH_CLIENT_REQUEST,
  };
};

const fetchClientSuccess = (client: Client[]) => {
  return {
    type: FETCH_CLIENT_SUCCESS,
    payload: client,
  };
};

const fetchClientError = (error: any) => {
  return {
    type: FETCH_CLIENT_ERROR,
    payload: error,
  };
};

export const fecthClientDetail = (id: any) => {
  const client = async () => {
    const config = getConfig();
    try {
      const res = await GET(`/client/${id}`, config);
  
      const data = await res.data;
        
  
      return data;
    } catch (error: any) {
      console.log("error", error.message);
    }
    
  };

  return {
    client,
  };
};

export const fecthClientData = (id: any) => {
  const config = getConfig();
  return async (dispatch: any) => {
    try {
      dispatch(fetchClient());
      const res = await GET(`/client/${id}`, config);
  
      const data = await res.data;
        
  
      dispatch(fetchClientSuccess(data));
    } catch (error: any) {
      dispatch(fetchClientError(error));
    }
  }
    
};

