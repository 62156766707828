import React from "react";
import RepaymentsUI from "../../modules/repayments/elements";

interface Props {}

const repaymentsPage: React.FC<Props> = () => {
  return (
    <>
      <RepaymentsUI />
    </>
  );
};

export default repaymentsPage;