import React from "react";

import LoginUI from "../../modules/login/elements";

interface Props {}

const LoginPage: React.FC<Props> = () => {
  return (
    <>
      <LoginUI />
    </>
  );
};

export default LoginPage;
