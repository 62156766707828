import React from "react";
import { ToastContainer } from "react-toastify";

import "react-toastify/dist/ReactToastify.css";

interface Props {
  children: React.ReactNode;
}

const ToastLayout: React.FC<Props> = ({ children }) => {
  return (
    <>
      <>{children}</>
      <ToastContainer />
    </>
  );
};

export default ToastLayout;
