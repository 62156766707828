import React from "react";
import { useParams } from "react-router-dom";
import RepaymentDetailModule from "../../modules/repayments/elements/clientdetail";

interface Props {}

const RepaymentDetailPage: React.FC<Props> = () => {
  const { id } = useParams();

  return (
    <>
      <RepaymentDetailModule id={id} />
    </>
  );
};

export default RepaymentDetailPage;
