import React, {useState} from "react";
import { x } from "@xstyled/emotion";
import { useLocation, Link } from "react-router-dom";

import { NavLinkItem } from "../elements";
import { spacing } from "../../theme";
import { urlTabs } from "../../enums";
import {  getUsername } from "../../helpers";
import { useLogout } from "../../hooks";

interface Props {}

type PathProps =
  | "login"
  | "register"
  | "dashboard"
  | "loans"
  | "client"
  | "repayments"
  | "reports"
  | "batchfiles";

const Header: React.FC<Props> = () => {
  const location = useLocation();

  const path: string | undefined = location.pathname.split("/")[1];

  const isIndex: boolean = !path; // true when path is '/'

  const activeTab = isIndex ? "dashboard" : (path as PathProps);

  const { logout } = useLogout();

  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);
  const [user, setUser] = useState<string>("");

  (async ()=>{
    const userObj: any = await getUsername();
    if(userObj === null){
      setIsAuthenticated(false);
    }else{
      setIsAuthenticated(true);
      setUser(userObj.user);
      //console.log(user);
    }
  })();

  return (
    <x.div
      position='sticky'
      bg='white'
      zIndex={1000}
      top={0}
      // w='100%'
      // maxW='100vw'
    >
      <x.div
        display='flex'
        justifyContent='space-between'
        alignItems='center'
        boxShadow='0px 4px 4px rgba(130, 130, 130, 0.4)'
        minH={spacing[16]}
        zIndex={100}
        px={spacing[4]}
        py={0}
      >
        <x.div display='flex' alignItems='center' py={0} px={0}>
          <x.img
            src='/images/dcslogo.png'
            alt='Debt Collection System'
            w='5rem'
            h='5rem'
            borderRadius='50%'
          />
        </x.div>
        <x.div display='flex' justifyContent='center'>
          {isAuthenticated ? (
            <>
              <x.p
                mr={spacing[2]}
                fontSize='0.9rem'
                fontWeight='600'
                color='primary-dark'
                mt='0.5rem'
              >
                Welcome, {user}
              </x.p>

              <x.button
                color={{ _: "primary-main", hover: "white" }}
                bg={{ _: "white", hover: "primary-dark" }}
                py={spacing[2]}
                minWidth='5.5rem'
                textAlign='center'
                mx={spacing[2]}
                transition='all ease-in 0.5s'
                borderRadius={spacing[2]}
                border='1px solid'
                borderColor='primary-main'
                outline='none'
                onClick={() => logout()}
              >
                Logout
              </x.button>
            </>
          ) : (
            <>
              <Link to='/login'>
                <NavLinkItem active={activeTab === urlTabs.login}>
                  Login
                </NavLinkItem>
              </Link>
              
            </>
          )}
        </x.div>
      </x.div>
    </x.div>
  );
};

export default Header;
