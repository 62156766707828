import React from "react";

import SignupModule from "../../modules/signup";

interface Props {}

const RegisterPage: React.FC<Props> = () => {
  return (
    <>
      <SignupModule />
    </>
  );
};

export default RegisterPage;
