import React from "react";
import {
  defaultTheme,
  ThemeProvider as XThemeProvider,
  Preflight,
} from "@xstyled/emotion";
import { x } from "@xstyled/emotion";

import { colorTheme, spacing } from "../theme";

interface Props {
  children: React.ReactNode;
}

const ThemeProvider: React.FC<Props> = ({ children }) => {
  const theme = {
    ...defaultTheme,
    colors: {
      ...defaultTheme.colors,
      ...colorTheme,
    },
    spacing: {
      ...spacing,
    },
  };

  return (
    <x.div>
      <XThemeProvider theme={theme}>
        <Preflight />
        {children}
      </XThemeProvider>
    </x.div>
  );
};

export default ThemeProvider;
