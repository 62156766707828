import { toast } from "react-toastify";

import { GET, POST, PUT } from "../../config";
import { getConfig } from "../../config/AxiosInstance";
import {
  CREATE_LOAN_REQUEST,
  FETCH_LOAN_DISBURSEMENTS,
  FETCH_LOAN_DISBURSEMENTS_ERROR,
  FETCH_LOAN_ERROR,
  FETCH_LOAN_REQUEST,
  FETCH_LOAN_SUCCESS,
  FETCH_LOAN_TYPES_ERROR,
  FETCH_LOAN_TYPES_REQUEST,
  FETCH_LOAN_TYPES_SUCCESS,
} from "./actionType";
import { getUsername } from "../../helpers";

import type { ClientLoan, LoanTypes } from "../../interfaces";

const fetchLoanTypesRequest = () => {
  return {
    type: FETCH_LOAN_TYPES_REQUEST,
  };
};

const fetchLoanTypesSuccess = (loantypes: LoanTypes[]) => {
  return {
    type: FETCH_LOAN_TYPES_SUCCESS,
    payload: loantypes,
  };
};

const fetchLoanTypesError = (error: any) => {
  return {
    type: FETCH_LOAN_TYPES_ERROR,
    payload: error,
  };
};

const createLoanTypwRequest = (loanType: LoanTypes) => {
  return {
    type: CREATE_LOAN_REQUEST,
    payload: loanType,
  };
};

const createClientLoanRequest = (loan: ClientLoan) => {
  return {
    type: CREATE_LOAN_REQUEST,
    payload: loan,
  };
};

const fetchLoansRequests = () => {
  return {
    type: FETCH_LOAN_REQUEST,
  };
};

const fetchLoansSuccess = (loanrequests: ClientLoan[]) => {
  return {
    type: FETCH_LOAN_SUCCESS,
    payload: loanrequests,
  };
};

const fetchLoansError = (error: any) => {
  return {
    type: FETCH_LOAN_ERROR,
    payload: error,
  };
};

const fetchLoanDisbursementsSuccess = (loans: any) => {
  return {
    type: FETCH_LOAN_DISBURSEMENTS,
    payload: loans,
  };
};

const fetchLoanDisbursementsError = (error: any) => {
  return {
    type: FETCH_LOAN_DISBURSEMENTS_ERROR,
    payload: error,
  };
};

export const fetchClientLoanTypes = () => {
  const config = getConfig();
  return async (dispatch: any) => {
    dispatch(fetchLoanTypesRequest());

    try {
      const res = await GET("/loantype", config);
      const data = await res.data;

      dispatch(fetchLoanTypesSuccess(data));
    } catch (error: any) {
      console.log("error", error);
      dispatch(fetchLoanTypesError(error));
    }
  };
};

export const createLoanRequestByClient = (
  client: ClientLoan, 
  navigate: any,
) => {
  const config = getConfig();

  

  return async (dispatch: any) => {
    try {
      const user: any = await getUsername();
      console.log(user);
      const res = await POST(
        "/LoanRequest",
        {
          loanAmount: client.loan_amt?.toString(),
          username: user.user,
          loantypeid: client.loantype?.toString(),
          processingfees: client.processingfees,
          clientid: client.id,
        },
        config
      );

      const {
        id,
        loanAmount: loan_amt,
        processingFeesPaid: processingfees,
        username: cso,
        createdOn: appliation_date,
        loanType: loantype,
      } = res.data;

      const extractedObject: ClientLoan = {
        id,
        loan_amt,
        processingfees,
        cso,
        appliation_date,
        loantype,
      };

      dispatch(createClientLoanRequest(extractedObject));
      toast.success("Loan request has been created successfully");
      navigate(`/client/loanhistory/${id}`);
    } catch (error) {
      toast.error("Loan application failed. Please try again.");
    }
  };
};

export const createLoanRequest = (client: ClientLoan, navigate: any) => {
  const config = getConfig();

  const user: any = getUsername();

  console.log(user);

  return async (dispatch: any) => {
    try {
      const res = await POST(
        "/LoanRequest",
        {
          loanAmount: client.loan_amt?.toString(),
          username: user.user,
          loantypeid: client.loantype?.toString(),
          processingfees: client.processingfees,
          clientid: client.id,
        },
        config
      );

      const {
        id,
        loanAmount: loan_amt,
        processingFeesPaid: processingfees,
        username: cso,
        createdOn: appliation_date,
        loanType: loantype,
      } = res.data;

      const extractedObject: ClientLoan = {
        id,
        loan_amt,
        processingfees,
        cso,
        appliation_date,
        loantype,
      };

      dispatch(createClientLoanRequest(extractedObject));
      toast.success("Loan request has been created successfully");
      navigate("/loans");
    } catch (error) {}
  };
};

export const editLoanRequest = (
  client: ClientLoan,
  navigate: any,
  user_id: number
) => {
  const config = getConfig();

  const user: any = getUsername();

  return async (dispatch: any) => {
    try {
      const res = await PUT(
        `/LoanRequest/${user_id}`,
        {
          id: user_id,
          loanAmount: client.loan_amt?.toString(),
          username: user,
          loantypeid: client.loantype?.toString(),
          processingFeesPaid: client.processingfees,
          clientid: client.id,
        },
        config
      );

      console.log("res", res.data);

      toast.success("Loan request has been updated successfully");
      navigate("/loans");
    } catch (error) {
      console.log("error", error);
      toast.error("Error while updating loan request");
    }
  };
};

export const fetchClientLoanRequests = () => {
  const config = getConfig();

  return async (dispatch: any) => {
    dispatch(fetchLoansRequests());
    try {
      const res = await GET("/loanrequest/", config);
      const data = await res.data;

      const loanrequest: ClientLoan[] = data.map((client: any) => ({
        id: client.id,
        loan_amt: client.loanAmount,
        processingfees: client.processingFeesPaid,
        appliation_date: client.createdOn,
        cso: client.username,
        loan_type: client.loanType.type,
      }));

      dispatch(fetchLoansSuccess(loanrequest));
    } catch (err: any) {
      console.log("error", err);
      dispatch(fetchLoansError(err));
    }
  };
};

export const fetchLoanDisbursements = () => {
  const config = getConfig();

  return async (dispatch: any) => {
    try {
      const res = await GET("/api/loandisbursement", config);

      const loans = await res.data;

      dispatch(fetchLoanDisbursementsSuccess(loans));
    } catch (error: any) {
      console.log("error", error);
      dispatch(fetchLoanDisbursementsError(error));
    }
  };
};
