import React, { Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { x } from "@xstyled/emotion";

type ModalVariant = "xs" | "sm" | "md" | "lg" | "xl" | "xl2";

interface ModalProps {
  open: boolean;
  hideCloseIcon?: boolean;
  onClose: () => void;
  children?: React.ReactNode;
  theme?: "light" | "light-dark";
  variant?: ModalVariant;
  showCloseText?: boolean;
}

const overlayBg = {
  light: "#00000011",
  ["light-dark"]: "#0000004d",
};

const modalVariants: Record<ModalVariant, string> = {
  xs: "max-w-1xl",
  sm: "max-w-2xl",
  md: "max-w-3xl",
  lg: "max-w-4xl",
  xl: "max-w-5xl",
  xl2: "max-w-6xl",
};

const Modal: React.FC<ModalProps> = ({
  open,
  onClose,
  hideCloseIcon,
  children,
  theme = "light-dark",
  variant = "sm",
  showCloseText,
}) => {
  if (!open) return null;

  return (
    <Transition appear show={open} as={Fragment}>
      <Dialog as='div' className='relative z-[99999]' onClose={onClose}>
        <Transition.Child
          as={Fragment}
          enter='transition duration-100 ease-out'
          enterFrom='transform scale-95 opacity-0'
          enterTo='transform scale-100 opacity-100'
          leave='transition duration-75 ease-out'
          leaveFrom='transform scale-100 opacity-100'
          leaveTo='transform scale-95 opacity-0'
        >
          <x.div bg={overlayBg[theme]} position='fixed' className='inset-0 ' />
        </Transition.Child>
        <div className='fixed inset-0 overflow-y-auto'>
          <div className='flex min-h-full items-center justify-center p-4 '>
            <Transition.Child
              as={Fragment}
              enter='transition duration-100 ease-out'
              enterFrom='transform scale-95 opacity-0'
              enterTo='transform scale-100 opacity-100'
              leave='transition duration-75 ease-out'
              leaveFrom='transform scale-100 opacity-100'
              leaveTo='transform scale-95 opacity-0'
            >
              <Dialog.Panel
                className={`w-full ${modalVariants[variant]} transform overflow-hidden rounded-2xl bg-white align-middle shadow-xl transition-all`}
              >
                <x.div
                  display={{ _: "hidden", sm: "block" }}
                  position='absolute'
                  top={0}
                  right={0}
                  pt={4}
                  pr={4}
                >
                  {!hideCloseIcon ? (
                    <x.button
                      type='button'
                      bg={{
                        _: "white",
                        hover: showCloseText && "primary-main-50",
                      }}
                      borderRadius={showCloseText ? "0.4rem" : "50%"}
                      py='0.2rem'
                      px={showCloseText ? "0.4rem" : "0.2rem"}
                      color={{ _: "gray-500", hover: "gray-600" }}
                      ring={{ focus: "2" }}
                      ringColor={{ focus: "primary-500" }}
                      outline={{ _: "none", focus: "none" }}
                      onClick={onClose}
                      title='Close'
                      aria-label='close modal'
                      aria-hidden={hideCloseIcon}
                      aria-labelledby='close modal'
                      display='inline-flex'
                      alignItems='center'
                      justifyContent='center'
                    >
                      {showCloseText ? "Close" : null}
                      <x.svg
                        h={6}
                        w={6}
                        xmlns='http://www.w3.org/2000/svg'
                        fill='none'
                        viewBox='0 0 24 24'
                        stroke='currentColor'
                        aria-hidden='true'
                      >
                        <x.path
                          strokeLinecap='round'
                          strokeLinejoin='round'
                          strokeWidth='2'
                          d='M6 18L18 6M6 6l12 12'
                        />
                      </x.svg>
                    </x.button>
                  ) : null}
                </x.div>
                <x.div p='0.5rem'>{children}</x.div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

export default Modal;
