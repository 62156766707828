import React, { useEffect } from "react";
import { Outlet, useNavigate } from "react-router-dom";

import { getToken } from "../helpers";


const PrivateRoutes = () => {
  const navigate = useNavigate();

  const token = getToken();

  useEffect(() => {
    if (token == null) {
      navigate("/login");
    }
    
  }, [token, navigate]);
  

  return <>{token ? <Outlet /> : null}</>;
};


export default PrivateRoutes;
