import { x } from "@xstyled/emotion";
import { useNavigate } from "react-router-dom";
import { getUsername } from "../helpers";
import { useEffect } from "react";

const HomePage = () => {
  const navigate = useNavigate();
  
  useEffect(() => {
    (async () =>{
      if(await getUsername() === null){    
          navigate("/login");    
      }else{
        navigate("/dashboard");
      }
    })();    
  }, [navigate]);
  return <x.div>HomePage</x.div>;
};

export default HomePage;
