import React, { useCallback, useEffect, useState } from "react";
import { x } from "@xstyled/emotion";
import { useSelect } from "downshift";

import type { SystemProps } from "@xstyled/emotion";
import type { ReactNode } from "react";
import { spacing } from "../../theme";

export interface DropdownProps
  extends Omit<
    React.HTMLAttributes<HTMLLIElement | HTMLUListElement>,
    "color" | "placeholder"
  > {}

export interface DropdownProps extends SystemProps {
  disabled?: boolean;
  placeholder?: ReactNode;
}

type OptionProps<T = any> = T;

export interface DropdownProps<T = any> {
  options: OptionProps<T>[];
  defaultOption?: OptionProps<T>;
  defaultValue?: string | number;
  renderOption: (option: OptionProps<T>, optionIndex?: number) => ReactNode;
  getSelectedOption?: (option: OptionProps<T>) => void;
  showSelectedOption?: boolean;
  isActiveOption?: (
    option: OptionProps<T>,
    selectedOption: OptionProps<T>
  ) => boolean;
  optionToString?: (option: T | null) => string;
  helperText?: string;
  variant?: "primary" | "error" | "success";
  keepDropdownOpen?: boolean;
  disableNull?: boolean;
}

function Dropdown<T = any>({
  children,
  options = [],
  defaultOption,
  placeholder,
  minWidth = "9.375rem",
  cursor = "default",
  mt = 1,
  borderRadius = true,
  padding,
  border = "1px solid",
  borderColor = "gray-300",
  paddingTop,
  paddingBottom,
  paddingLeft,
  paddingRight,
  letterSpacing,
  renderOption,
  onClick,
  getSelectedOption,
  isActiveOption,
  showSelectedOption = true,
  disabled,
  helperText,
  optionToString,
  variant = "primary",
  keepDropdownOpen = false,
  defaultValue,
  disableNull = true,
  ...props
}: DropdownProps<T>) {
  const [selectedItem, setSelectedItem] =
    useState<OptionProps<T | undefined>>(defaultOption);

  const {
    isOpen,
    highlightedIndex,
    getToggleButtonProps,
    getMenuProps,
    selectItem,
    getItemProps,
  } = useSelect({
    defaultSelectedItem: selectedItem,
    initialSelectedItem: selectedItem,
    items: options,
    selectedItem,
    itemToString: optionToString,
    onSelectedItemChange: (changes) => {
      setSelectedItem(changes.selectedItem || undefined);
      if (typeof changes.selectedItem !== "undefined") {
        getSelectedOption?.(changes.selectedItem as T);
      }
    },
    stateReducer: (_state, actionAndChanges) => {
      const { changes, type } = actionAndChanges;

      switch (type) {
        case useSelect.stateChangeTypes.ItemClick:
          return { ...changes, isOpen: keepDropdownOpen };

        default:
          return changes;
      }
    },
  });

  const handleSelect = useCallback(() => {
    setSelectedItem(defaultOption);
    selectItem(typeof defaultOption === "undefined" ? null : defaultOption);
  }, [defaultOption]);

  useEffect(handleSelect, [defaultValue]);

  return (
    <>
      <x.div
        display='block'
        w='100%'
        position='relative'
        mt={mt}
        padding={padding}
      >
        <x.div
          type='button'
          bg={disabled ? "gray-200" : "white"}
          position='relative'
          w='100%'
          display='flex'
          flex={1}
          alignItems='center'
          border={border}
          borderColor={borderColor}
          minHeight='2.375rem'
          boxSizing='border-box'
          borderRadius={borderRadius}
          textAlign='left'
          outline={{ _: "none", focus: "none" }}
          cursor={cursor}
          aria-haspopup='listbox'
          aria-labelledby='listbox-label'
          minWidth={minWidth}
          overflow='hidden'
          {...getToggleButtonProps()}
        >
          <x.div
            h='100%'
            display='flex'
            alignItems='center'
            w='100%'
            py={0.5}
            pl={4}
            pr={spacing["12"]}
            whiteSpace='nowrap'
            color={
              !(
                selectedItem ||
                (typeof selectedItem === "number" && selectedItem === 0)
              )
                ? "gray-500"
                : undefined
            }
            letterSpacing={letterSpacing}
            paddingLeft={paddingLeft}
          >
            {showSelectedOption &&
            (selectedItem ||
              (typeof selectedItem === "number" && selectedItem === 0))
              ? renderOption?.(selectedItem) ?? placeholder
              : placeholder ?? null}
          </x.div>
          <x.button
            ml={3}
            position='absolute'
            right={0}
            top='0.313rem'
            bottom='0.313rem'
            display='flex'
            alignItems='center'
            justifyContent='center'
            px={1}
            pointerEvents='none'
            bg={disabled ? "gray-200" : "white"}
            borderLeft='1px solid'
            borderColor='gray-300'
          >
            <x.svg
              data-accordion-icon
              w={5}
              h={5}
              fill='currentColor'
              viewBox='0 0 20 20'
              xmlns='http://www.w3.org/2000/svg'
            >
              <x.path
                d='M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z'
                strokeLinecap='round'
                strokeLinejoin='round'
              />
            </x.svg>
          </x.button>
        </x.div>
        <x.ul
          position='absolute'
          top='auto'
          zIndex={10}
          w='100%'
          minWidth={minWidth}
          bg='white'
          boxShadow
          borderRadius={borderRadius}
          maxHeight={56}
          px={0}
          textAlign='left'
          overflowY='auto'
          outline={{
            _: "none",
            focus: "none",
          }}
          tabIndex={-1}
          role='listbox'
          aria-labelledby='listbox-label'
          aria-activedescendant={highlightedIndex}
          {...props}
          {...getMenuProps()}
        >
          {isOpen &&
            options.map((option, optionIndex) => (
              <x.li
                display='block'
                key={`listbox-option-${optionIndex}`}
                color={{
                  _:
                    isActiveOption?.(option, selectedItem as T) ||
                    highlightedIndex === optionIndex
                      ? !disableNull && option === null
                        ? "gray-900"
                        : "white"
                      : option === null
                      ? "gray-500"
                      : "gray-900",
                  focus: option === null ? "gray-500" : "white",
                }}
                cursor={cursor}
                w={{ sm: "auto", xs: "100%" }}
                pl={3}
                pr={9}
                mx={0}
                bg={{
                  _:
                    option !== null &&
                    (isActiveOption?.(option, selectedItem as T) ||
                      highlightedIndex === optionIndex)
                      ? "primary-main"
                      : "white",
                  focus: option !== null && "primary-main",
                }}
                boxSizing='border-box'
                transition='all 0.3s ease-in-out'
                whiteSpace='nowrap'
                minWidth={minWidth}
                id={`listbox-option-${optionIndex}`}
                role='option'
                borderBottom='1px solid'
                borderColor='white'
                aria-selected={highlightedIndex === optionIndex}
                disabled={
                  disableNull && (option === null || option === undefined)
                }
                {...getItemProps({
                  item: option,
                  index: optionIndex,
                  disabled:
                    disableNull && (option === null || option === undefined),
                })}
              >
                {renderOption?.(option)}
              </x.li>
            ))}
        </x.ul>
        <x.div tabIndex={0} />
      </x.div>
      {helperText && variant !== "primary" ? (
        <x.p
          fontSize='sm'
          mt={-1}
          color={variant === "success" ? "success-600" : "danger-600"}
        >
          {helperText}
        </x.p>
      ) : null}
    </>
  );
}

export default Dropdown;
