import {
  FETCH_ROLES_ERROR,
  FETCH_ROLES_REQUEST,
  FETCH_ROLES_SUCCESS,
} from "./actionTypes";

import { UserRoles } from "../../interfaces";

interface Props {
  loading: boolean;
  roles: UserRoles[];
  error: string;
}

const initialState: Props = {
  loading: false,
  roles: [],
  error: "",
};

export const rolesReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case FETCH_ROLES_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case FETCH_ROLES_SUCCESS:
      return {
        loading: false,
        roles: action.payload,
        error: "",
      };
    case FETCH_ROLES_ERROR:
      return {
        loading: false,
        roles: [],
        error: action.payload,
      };

    default:
      return state;
  }
};
