import {
  FETCH_CLIENT_LOAN_HISTORY_ERROR,
  FETCH_CLIENT_LOAN_HISTORY_REQUEST,
  FETCH_CLIENT_LOAN_HISTORY_SUCCESS,
} from "./actionTypes";
import type { ClientLoan } from "../../../interfaces";

interface IClientLoan {
  loading: boolean;
  clientLoans: ClientLoan[];
  error: any;
}

const initialState: IClientLoan = {
  loading: false,
  clientLoans: [],
  error: "",
};

export const loanHistoryReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case FETCH_CLIENT_LOAN_HISTORY_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case FETCH_CLIENT_LOAN_HISTORY_SUCCESS:
      return {
        loading: false,
        clientLoans: action.payload,
        error: "",
      };
    case FETCH_CLIENT_LOAN_HISTORY_ERROR:
      return {
        loading: false,
        clientLoans: null,
        error: action.payload,
      };
    default:
      return state;
  }
};
