import React, { useEffect, useState } from "react";
import { x } from "@xstyled/emotion";
import { Link } from "react-router-dom";
import { Icon } from "@iconify/react";
import editIcon from "@iconify/icons-material-symbols/edit";
import trashIcon from "@iconify/icons-ph/trash";

import { fetchLoanRequestDetail } from "../../hooks/LoanRequestDetail";
import { spacing } from "../../../../../theme";
import Grid from "../../../../../components/elements/Grid";
import { formatCurrency } from "../../../../dashboard/helpers";

interface Props {
  id: any;
}

const LoanRequestDetail: React.FC<Props> = ({ id }) => {
  const [client, setClient] = useState<any>();

  useEffect(() => {
    const { loanRequest: clientData } = fetchLoanRequestDetail(id);

    clientData()
      .then((user) => {
        setClient(user);
      })
      .catch((error) => {
        console.error("Error fetching client:", error);
      });
  }, [id]);

  return (
    <x.div
      w='100%'
      p={spacing[4]}
      bg='white'
      borderRadius
      zIndex={2999}
      minH='25rem'
    >
      <x.div display='flex' justifyContent='flex-start' alignItems='center'>
        <x.img src='/images/avatar.png' w='6rem' h='6rem' borderRadius='50%' />
        <x.div ml={spacing[2]}>
          <x.h3
            textTransform='capitalize'
            fontSize='0.9rem'
            fontWeight='600'
            mb={spacing[2]}
          >
            {client?.client?.name}
          </x.h3>
          <x.p
            textTransform='capitalize'
            fontSize='0.9rem'
            color='secondary-600'
          >
            {client?.client.businessName}
          </x.p>
        </x.div>
      </x.div>
      <x.div
        display='flex'
        flexDirection={{ _: "column", sm: "row" }}
        my={spacing[4]}
        justifyContent='flex-start'
        gap={spacing[2]}
      >
        <x.div
          // bg='red'
          flex={1}
          p={spacing[4]}
          borderRadius='0.5rem'
          minH='5rem'
          boxShadow
          mb={{ _: spacing[2], sm: 0 }}
        >
          <x.h3 mb={spacing[3]} fontSize='0.9rem' fontWeight='600'>
            Loan amount
          </x.h3>
          <x.p fontSize='0.8rem' color='secondary-600' fontWeight='600'>
            KES:{" "}
            {client?.loanAmount
              ? formatCurrency(client?.loanAmount)
              : formatCurrency(0)}
          </x.p>
        </x.div>
        <x.div
          boxShadow
          flex={1}
          p={spacing[4]}
          borderRadius='0.5rem'
          minH='5rem'
          mb={{ _: spacing[2], sm: 0 }}
        >
          <x.h3 mb={spacing[3]} fontSize='0.9rem' fontWeight='600'>
            Loan status
          </x.h3>
          <x.p fontSize='0.8rem' color='secondary-600' fontWeight='600'>
            {client?.loanRequestStatus?.status}
          </x.p>
        </x.div>
        <x.div
          boxShadow
          flex={1}
          p={spacing[4]}
          borderRadius='0.5rem'
          minH='5rem'
          mb={{ _: spacing[2], sm: 0 }}
        >
          <x.h3 mb={spacing[3]} fontSize='0.9rem' fontWeight='600'>
            Processing fee
          </x.h3>
          <x.p fontSize='0.8rem' color='secondary-600' fontWeight='600'>
            KES: {formatCurrency(client?.processingFeesPaid)}
          </x.p>
        </x.div>
        <x.div
          boxShadow
          flex={1}
          p={spacing[4]}
          borderRadius='0.5rem'
          minH='5rem'
          mb={{ _: spacing[2], sm: 0 }}
          display='flex'
          justifyContent='space-between'
        >
          <x.div>
            {" "}
            <x.h3 mb={spacing[3]} fontSize='0.9rem' fontWeight='600'>
              Loan type
            </x.h3>
            <x.p fontSize='0.8rem' color='secondary-600' fontWeight='600'>
              {client?.loanType?.type}
            </x.p>
          </x.div>
          <x.div>
            {" "}
            <x.h3 mb={spacing[3]} fontSize='0.9rem' fontWeight='600'>
              Duration
            </x.h3>
            <x.p fontSize='0.8rem' color='secondary-600' fontWeight='600'>
              {client?.loanType?.duration} months
            </x.p>
          </x.div>
        </x.div>
      </x.div>
      <x.div my={spacing[2]}>
        <Grid container>
          <Grid item spacing={1}>
            <x.div w='100%'>
              <x.p fontSize='0.8rem' fontWeight='600'>
                ID number
              </x.p>
              <x.div
                my={spacing[2]}
                borderRadius
                w='100%'
                bg='secondary-200'
                fontSize='0.8rem'
                fontWeight='600'
                p={spacing[2]}
              >
                {client?.client?.idNumber}
              </x.div>
            </x.div>
          </Grid>
          <Grid item spacing={1}>
            <x.div w='100%'>
              <x.p fontSize='0.8rem' fontWeight='600'>
                Kra pin
              </x.p>
              <x.div
                my={spacing[2]}
                borderRadius
                w='100%'
                bg='secondary-200'
                fontSize='0.8rem'
                fontWeight='600'
                p={spacing[2]}
              >
                {client?.client?.kraPin}
              </x.div>
            </x.div>
          </Grid>
        </Grid>
      </x.div>
      <Grid container>
        <Grid item spacing={1}>
          <x.div
            bg='secondary-200'
            p={spacing[2]}
            w='100%'
            mb={spacing[2]}
            borderRadius
          >
            <x.p color='secondary-600' fontSize='0.8rem' fontWeight='600'>
              Email
            </x.p>
            <x.p fontSize='0.9rem' fontWeight='500' mt={spacing[2]}>
              {client?.client?.email}
            </x.p>
          </x.div>
        </Grid>
        <Grid item spacing={1}>
          <x.div
            bg='secondary-200'
            p={spacing[2]}
            w='100%'
            mb={spacing[2]}
            borderRadius
          >
            <x.p color='secondary-600' fontSize='0.8rem' fontWeight='600'>
              Phone number
            </x.p>
            <x.p fontSize='0.9rem' fontWeight='500' mt={spacing[2]}>
              {client?.client?.telephone}
            </x.p>
          </x.div>
        </Grid>
      </Grid>

      <Grid container>
        <Grid item spacing={1}>
          <x.div
            bg='secondary-200'
            p={spacing[2]}
            w='100%'
            mb={spacing[2]}
            borderRadius
          >
            <x.p color='secondary-600' fontSize='0.8rem' fontWeight='600'>
              Business name
            </x.p>
            <x.p fontSize='0.9rem' fontWeight='500' mt={spacing[2]}>
              {client?.client?.businessName}
            </x.p>
          </x.div>
        </Grid>
        <Grid item spacing={1}>
          <x.div
            bg='secondary-200'
            p={spacing[2]}
            w='100%'
            mb={spacing[2]}
            borderRadius
          >
            <x.p color='secondary-600' fontSize='0.8rem' fontWeight='600'>
              Business number
            </x.p>
            <x.p fontSize='0.9rem' fontWeight='500' mt={spacing[2]}>
              {client?.client?.businessNumber}
            </x.p>
          </x.div>
        </Grid>
      </Grid>

      <x.div
        bg='secondary-200'
        p={spacing[2]}
        w='100%'
        mb={spacing[2]}
        borderRadius
        mx={spacing[1]}
      >
        <x.p color='secondary-600' fontSize='0.8rem' fontWeight='600'>
          Registration fee
        </x.p>
        <x.p fontSize='0.9rem' fontWeight='500' mt={spacing[2]}>
          KES: {formatCurrency(client?.client?.registrationFeePaid)}
        </x.p>
      </x.div>
      <x.div
        display='flex'
        justifyContent='flex-end'
        alignItems='center'
        mt={spacing[4]}
      >
        <x.div display='flex' justifyContent='flex-end' alignItems='center'>
          <Link to={`/loans/edit/${client?.id}`}>
            <x.button
              outline={{ _: "none", focus: "none" }}
              border='1px solid'
              borderColor='primary-main'
              color='primary-main'
              h='2rem'
              w='2rem'
              borderRadius
              display='flex'
              justifyContent='center'
              alignItems='center'
            >
              <Icon
                style={{
                  fontSize: "1.5rem",
                }}
                icon={editIcon}
              />
            </x.button>
          </Link>

          <x.button
            outline={{ _: "none", focus: "none" }}
            border='1px solid'
            borderColor='primary-main'
            color='primary-main'
            h='2rem'
            w='2rem'
            borderRadius
            display='flex'
            justifyContent='center'
            alignItems='center'
            ml={spacing[2]}
          >
            <Icon
              style={{
                fontSize: "1.5rem",
              }}
              icon={trashIcon}
            />
          </x.button>
        </x.div>
      </x.div>
    </x.div>
  );
};

export default LoanRequestDetail;
