import { useState } from "react";
import { toast } from "react-toastify";
import {getToken} from "../../../helpers"
import { POST } from "../../../config";

import type { SignupProps } from "../interfaces";

export const useSignup = (navigate: any) => {
  const [error, setError] = useState("");
  const token = getToken();

  const signup = async (user: SignupProps) => {
    try {
      await POST(
        "/authenticate/register",
        JSON.stringify({
          username: user.username,
          email: user.email,
          password: user.password,
          role: "Admin",
        }),
        {
          headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${token}`,
          },
        }
      );

      toast.success("Account has been successfully created");
      navigate("/");
    } catch (err: any) {
      toast.error("Error while creating account");
      setError(err);
    }
  };
  return {
    signup,
    error,
  };
};
