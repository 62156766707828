import React, { useCallback, useEffect, useState } from "react";
import styled, { x } from "@xstyled/emotion";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { Icon } from "@iconify/react";
import moneyLine from "@iconify/icons-majesticons/money-line";

import Grid from "../../../../../components/elements/Grid";
import Dropdown from "../../../../../components/elements/Drodown";
import { fetchOnboardedClients } from "../../../../../redux/clients/onboarding/actionCreators";
import {
  editLoanRequest,
  fetchClientLoanTypes,
} from "../../../../../redux/loans/actionCreators";
import { spacing } from "../../../../../theme";

import { IClient } from "../../interfaces";
import { LoanTypes } from "../../../../../interfaces";
import { fetchLoanRequestDetail } from "../../hooks/LoanRequestDetail";
import { ClientLoan } from "../..../../../../../../interfaces";

interface Props {
  id: any;
}

const Wrapper = styled("div")`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
`;

const EditClientLoanRequest: React.FC<Props> = ({ id }) => {
  const [formData, setFormData] = useState<ClientLoan>({
    loan_amt: 3500,
    loantype: null,
    processingfees: 500,
    cso: null,
    id: 0,
    appliation_date: null
  });
  const [defaultClient, setDefaultClient] = useState<IClient | undefined>();
  const [defaultLoanType, setDefaultLoanType] = useState<LoanTypes | undefined>();

  const clients = useSelector((state: any) => state.onboarding);
  const loantypes = useSelector((state: any) => state.loans);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const clientData = clients.clients.map((client: any) => ({
    id: client.id,
    username: client.name,
  }));

  const { loanRequest } = fetchLoanRequestDetail(id);

  const handleChange = (data: Partial<ClientLoan>) => {
    setFormData((prev) => ({ ...prev, ...data }));
  };

  const handleSelectLoanType = (option: LoanTypes) => {
    handleChange({ loantype: option.id });
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    //@ts-expect-error
    await dispatch(editLoanRequest(formData, navigate, id));
  };

  const fetchLoanTypes = useCallback(() => {
    //@ts-expect-error
    dispatch(fetchClientLoanTypes());
  }, [dispatch]);

  const fetchLoan = useCallback(() => {
    loanRequest()
      .then((data: any) => {
        const { client, loanAmount, processingFeesPaid, loanType, createdOn, username } = data;

        const formattedClientData: ClientLoan = {
          id: client?.id,
          loan_amt: loanAmount,
          loantype: loanType?.id,
          processingfees: processingFeesPaid,
          appliation_date: createdOn,
          cso: username,
        };

        setFormData(formattedClientData);
        setDefaultClient(client);
        setDefaultLoanType(loanType)

      })
      .catch((error) => {
        console.error("Error fetching client:", error);
      });
  }, [loanRequest]);

  useEffect(() => {
    fetchLoan();
  }, []);

  useEffect(() => {
    fetchLoanTypes();
  }, [fetchLoanTypes]);

  return (
    <x.form
      onSubmit={handleSubmit}
      w='100%'
      bg='white'
      borderRadius
      minH='50vh'
      p={spacing[4]}
    >
      <x.div display='flex' alignItems='center' justifyContent='space-between'>
        <x.h3 fontSize='1.3rem' fontWeight='600' m={spacing[2]}>
          Edit Applicant Details
        </x.h3>
        <Link to={`/client/clientloanrequests/${defaultClient?.id}`}>
          <x.button
            outline={{ _: "none", focus: "none" }}
            border='1px solid'
            borderColor='transparent'
            bg={{ _: "primary-main", hover: "primary-dark" }}
            color='white'
            px={spacing[4]}
            py={spacing[2]}
            borderRadius
            mr={spacing[4]}
          >
            Back
          </x.button>
        </Link>
      </x.div>

      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} md={6} lg={6}>
          <Wrapper>
            <x.label fontSize='0.9rem' fontWeight='500' mb={spacing[2]}>
              Loan Type
            </x.label>

            <x.div mt={-1} mr={{ _: 0, md: spacing[2] }}>
              <Dropdown<LoanTypes>
                placeholder='Select loan type'
                options={loantypes.loans}
                renderOption={(option) => (
                  <x.p py={1} mt={1} w='100%'>
                    {option?.type}
                  </x.p>
                )}
                optionToString={(option) => option?.type || ""}
                isActiveOption={(option, selected) => {
                  if (option && selected) {
                    return option.id === selected.id;
                  }
                  return false;
                }}
                defaultOption={defaultLoanType}
                //@ts-expect-error
                defaultValue={defaultLoanType?.id}
                getSelectedOption={handleSelectLoanType}
              />
            </x.div>
          </Wrapper>
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} md={6} lg={6}>
          <Wrapper>
            <x.label fontSize='0.9rem' fontWeight='500' mb={spacing[2]}>
              Amount
            </x.label>
            <x.div
              display='flex'
              alignItems='center'
              justifyContent='flex-start'
              mr={{ _: 0, md: spacing[2] }}
            >
              <x.div
                display='flex'
                justifyContent='center'
                alignItems='center'
                bg='secondary-400'
                w='2.275rem'
                h='2.275rem'
              >
                <Icon
                  style={{
                    color: "#3f3f46ff",
                    fontSize: "1.2rem",
                  }}
                  icon={moneyLine}
                />
              </x.div>
              <x.input
                placeholder='Enter amount'
                type='number'
                outline='none'
                border='1px solid'
                borderColor='secondary-200'
                w='100%'
                py={spacing[2]}
                px={spacing[2]}
                name='phone'
                onChange={(e) =>
                  handleChange({ loan_amt: Number(e.target.value) })
                }
                value={formData.loan_amt?.toString()}
              />
            </x.div>
          </Wrapper>
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} md={6} lg={6}>
          <Wrapper>
            <x.label fontSize='0.9rem' fontWeight='500' mb={spacing[2]}>
              Processing Fee
            </x.label>
            <x.div
              display='flex'
              alignItems='center'
              justifyContent='flex-start'
              mr={{ _: 0, md: spacing[2] }}
            >
              <x.div
                display='flex'
                justifyContent='center'
                alignItems='center'
                bg='secondary-400'
                w='2.275rem'
                h='2.275rem'
              >
                <Icon
                  style={{
                    color: "#3f3f46ff",
                    fontSize: "1.2rem",
                  }}
                  icon={moneyLine}
                />
              </x.div>
              <x.input
                placeholder='Enter amount'
                type='number'
                outline='none'
                border='1px solid'
                borderColor='secondary-200'
                w='100%'
                py={spacing[2]}
                px={spacing[2]}
                name='phone'
                onChange={(e) =>
                  handleChange({ processingfees: Number(e.target.value) })
                }
                value={formData.processingfees?.toString()}
              />
            </x.div>
          </Wrapper>
        </Grid>
      </Grid>
      <x.div my={spacing[4]} mx={spacing[2]}>
        <x.button
          outline={{ _: "none", focus: "none" }}
          border='1px solid'
          borderColor='transparent'
          bg={{ _: "primary-main", hover: "primary-dark" }}
          color='white'
          px={spacing[6]}
          py={spacing[2]}
          borderRadius
          type='submit'
        >
          Submit
        </x.button>
      </x.div>
    </x.form>
  );
};

export default EditClientLoanRequest;
