import type { Column } from "react-table";
import type { RepaymentProps } from "../../interfaces";

export const repaymentsTableColumns: Column<RepaymentProps>[] = [
  {
    Header: "No",
    accessor: "no",
    //@ts-expect-error
    disableGlobalFilter: true,
  },
  {
    Header: "Name",
    accessor: "name",
  },
  {
    Header: "Phone",
    accessor: "msisdn",
  },
  {
    Header: "Transaction ID",
    accessor: "transaction_id",
  },
  {
    Header: "Amount",
    accessor: "transaction_amt",
    //@ts-expect-error
    disableGlobalFilter: true,
  },
  {
    Header: "Organization Balance",
    accessor: "org_bal",
    //@ts-expect-error
    disableGlobalFilter: true,
  },
  {
    Header: "Date",
    accessor: "transaction_time",
  },

  {
    Header: "Business Code",
    accessor: "business_code",
    //@ts-expect-error
    disableGlobalFilter: true,
  },
  {
    Header: "Actions",
    accessor: "id",
    //@ts-expect-error
    disableGlobalFilter: true,
  },
];
