import React from "react";
import BatchFilesUI from "../../modules/batch/elements";

const BatchFilesPage = () => {
  return (
    <>
      <BatchFilesUI />
    </>
  );
};

export default BatchFilesPage;
