import {
  FETCH_BRANCHES_ERROR,
  FETCH_BRANCHES_REQUEST,
  FETCH_BRANCHES_SUCCESS,
} from "./actionTypes";
import { GET } from "../../config";
import { getConfig } from "../../config/AxiosInstance";

const fetchBranchesRequest = () => {
  return {
    type: FETCH_BRANCHES_REQUEST,
  };
};

const fetchBranchesSuccess = (branches: any[]) => {
  return {
    type: FETCH_BRANCHES_SUCCESS,
    payload: branches,
  };
};

const fetchBranchesError = (error: any) => {
  return {
    type: FETCH_BRANCHES_ERROR,
    payload: error,
  };
};

export const fetchBranches = () => {
  const config = getConfig();

  return async (dispatch: any) => {
    dispatch(fetchBranchesRequest());

    try {
      const res = await GET("/branch", config);

      const data = await res.data;

      dispatch(fetchBranchesSuccess(data));
    } catch (error: any) {
      dispatch(fetchBranchesError(error.message));
    }
  };
};
