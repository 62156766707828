import React from "react";

import ClientOnboarding from "../../modules/clients/elements/onboarding";

interface Props {}

const ClientOnboardinPage: React.FC<Props> = () => {
  return (
    <>
      <ClientOnboarding />
    </>
  );
};

export default ClientOnboardinPage;
