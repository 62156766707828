import React from "react";
import { x } from "@xstyled/emotion";
import { Outlet } from "react-router-dom";

import { Header, Sidebar } from "./index";
// import { getToken } from "../../helpers";
import { spacing } from "../../theme";

interface Props {}

const Layout: React.FC<Props> = () => {
  // const token = getToken();

  return (
    <x.div w='100vw' maxW='100vw'>
      <Header />
      <x.div display='flex' position='relative' minH='85vh'>
        <Sidebar />
        <x.div
          flex={1}
          maxW='calc(100vw - 3rem)'
          p={spacing[4]}
          style={{ overflowX: "auto" }}
        >
          <Outlet />
        </x.div>
      </x.div>
    </x.div>
  );
};

export default Layout;
