import React, { useState } from "react";
import { Icon } from "@iconify/react";
import styled, { x } from "@xstyled/emotion";
import { Link, useNavigate } from "react-router-dom";
import eyeOffOutline from "@iconify/icons-mdi/eye-off-outline";
import eyeOutline from "@iconify/icons-mdi/eye-outline";

import { Required } from "../../../components/elements";
import { useSignup } from "../hooks/useSignup";
import { spacing } from "../../../theme";

import type { SignupProps } from "../interfaces";

interface Props {}

const Wrapper = styled("div")`
  display: flex;
  flex-direction: column;
  margin-top: 1rem;
  justify-content: flex-start;
  width: 100%;
`;

const RegisterFormUI: React.FC<Props> = () => {
  const [formData, setFormData] = useState<SignupProps>({
    email: "",
    username: "",
    password: "",
  });

  const [confirmPassword, setConfirmPassword] = useState<string>("");
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [showConfirmPassword, setShowConfirmPassword] =
    useState<boolean>(false);

  const navigate = useNavigate();

  const { signup } = useSignup(navigate);

  const toogleShowPassword = () => {
    setShowPassword((prev) => !prev);
  };

  const toogleShowConfirmPassword = () => {
    setShowConfirmPassword((prev) => !prev);
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;

    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (
      formData.password &&
      confirmPassword &&
      formData.password !== confirmPassword
    ) {
      console.log("passwords do not match");
    }

    await signup(formData);
  };

  return (
    <x.div px={spacing[4]} my={spacing[4]}>
      <x.h3 fontSize='1.5rem' fontWeight='600' my={spacing[4]}>
        Create an Account
      </x.h3>
      <x.div>
        <x.small color='danger-600'>
          <x.i>
            <Required /> indicates required field
          </x.i>
        </x.small>
      </x.div>
      <x.form onSubmit={handleSubmit}>
        <x.div
          display='flex'
          justifyContent='space-between'
          alignItems='center'
          flexDirection={{ _: "column", md: "row" }}
          m={0}
        >
          <x.div w='100%' pr={{ _: 0, md: spacing[2] }}>
            <Wrapper>
              <x.label fontSize='1rem' fontWeight='600' mb={spacing[2]}>
                Username <Required />
              </x.label>
              <x.input
                w='100%'
                name='username'
                type='text'
                outline='none'
                border='1px solid'
                p={spacing[2]}
                borderColor='secondary-200'
                borderRadius
                placeholder='Firstname'
                value={formData.username}
                onChange={handleChange}
              />
            </Wrapper>
          </x.div>
        </x.div>

        <Wrapper>
          <x.label fontSize='1rem' fontWeight='600' mb={spacing[2]}>
            Email <Required />
          </x.label>
          <x.input
            w='100%'
            name='email'
            type='email'
            outline='none'
            border='1px solid'
            p={spacing[2]}
            borderColor='secondary-200'
            borderRadius
            placeholder='Email'
            value={formData.email}
            onChange={handleChange}
          />
        </Wrapper>

        <x.div
          display='flex'
          justifyContent='space-between'
          alignItems='center'
          flexDirection={{ _: "column", md: "row" }}
          m={0}
        >
          <x.div w='100%' pr={{ _: 0, md: spacing[2] }} position='relative'>
            <Wrapper>
              <x.label fontSize='1rem' fontWeight='600' mb={spacing[2]}>
                Password <Required />
              </x.label>
              <x.input
                type={showPassword ? "text" : "password"}
                name='password'
                w='100%'
                outline='none'
                border='1px solid'
                p={spacing[2]}
                borderColor='secondary-200'
                borderRadius
                placeholder='Password'
                value={formData.password}
                onChange={handleChange}
              />
              {showPassword ? (
                <StyledIcon icon={eyeOffOutline} onClick={toogleShowPassword} />
              ) : (
                <StyledIcon icon={eyeOutline} onClick={toogleShowPassword} />
              )}
            </Wrapper>
          </x.div>
          <x.div w='100%' pl={{ _: 0, md: spacing[2] }} position='relative'>
            <Wrapper>
              <x.label fontSize='1rem' fontWeight='600' mb={spacing[2]}>
                Confirm Password <Required />
              </x.label>
              <x.input
                type={showConfirmPassword ? "text" : "password"}
                name='confirmPassword'
                w='100%'
                outline='none'
                border='1px solid'
                p={spacing[2]}
                borderColor='secondary-200'
                borderRadius
                placeholder='Confirm Password'
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
              />
              {showConfirmPassword ? (
                <StyledIcon
                  icon={eyeOffOutline}
                  onClick={toogleShowConfirmPassword}
                />
              ) : (
                <StyledIcon
                  icon={eyeOutline}
                  onClick={toogleShowConfirmPassword}
                />
              )}
            </Wrapper>
          </x.div>
        </x.div>
        <Wrapper>
          <x.button
            borderRadius
            border='1px solid'
            borderColor='primary-main'
            w='100%'
            py={spacing[2]}
            mt={spacing[4]}
            bg={{ _: "primary-main", hover: "white" }}
            transition='all 0.7s ease-in'
            color={{ _: "white", hover: "primary-main" }}
            fontSize='1rem'
            fontWeight='600'
          >
            Sign Up
          </x.button>
        </Wrapper>
        <x.div
          display='flex'
          alignItems='center'
          justifyContent='flex-end'
          marginTop={spacing[4]}
        >
          <x.small>
            Already have an account? <Link to='/login'> login</Link>
          </x.small>
        </x.div>
      </x.form>
    </x.div>
  );
};

export const StyledIcon = styled(Icon)`
  position: absolute;
  top: 3.2rem;
  right: 1rem;
  font-size: 1.2rem;
  color: secondary-400;
  cursor: pointer;
`;

export default RegisterFormUI;
