import React from "react";
import { useParams } from "react-router-dom";

import LoanRequestDetail from "../../../modules/loans/clientloanrequest/elements/loanrequestdetail";

interface Props {}

const LoanRequestDetailPage: React.FC<Props> = () => {
  const { id } = useParams();
  return (
    <>
      <LoanRequestDetail id={id} />
    </>
  );
};

export default LoanRequestDetailPage;
