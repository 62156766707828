import React from "react";
import { useParams } from "react-router-dom";

import ClientLoanHistoryModule from "../../../modules/clients/elements/clientloanhistory";

interface Props {}

const ClientLoanHistoryPage: React.FC<Props> = () => {
  const { id } = useParams();

  return (
    <>
      <ClientLoanHistoryModule id={id} />
    </>
  );
};

export default ClientLoanHistoryPage;
