import {
  FETCH_CLIENT_ERROR,
  FETCH_CLIENT_REQUEST,
  FETCH_CLIENT_SUCCESS,
  FETCH_CLIENTS_ERROR,
  FETCH_CLIENTS_REQUEST,
  FETCH_CLIENTS_SUCCESS,
} from "./actionTypes";

interface InitialClientsProps {
  loading: boolean;
  clients: any[];
  error: string;
}

interface InitialClientProps {
  loading: boolean;
  client: any[];
  error: string;
}

const initialClientsState: InitialClientsProps = {
  loading: false,
  clients: [],
  error: "",
};

const initialClientState: InitialClientProps = {
  loading: false,
  client: [],
  error: "",
};

export const clientsReducer = (state = initialClientsState, action: any) => {
  switch (action.type) {
      case FETCH_CLIENTS_REQUEST:
        return {
          ...state,
          loading: true,
        };
      case FETCH_CLIENTS_SUCCESS:
        return {
          loading: false,
          clients: action.payload,
          error: "",
        };
      case FETCH_CLIENTS_ERROR:
        return {
          loading: false,
          clients: [],
          error: action.payload,
        };
    default:
      return state;
  }
};

export const clientReducer = (state = initialClientState, action: any) => {
  switch (action.type) {
    case FETCH_CLIENT_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case FETCH_CLIENT_SUCCESS:
      return {
        loading: false,
        client: action.payload,
        error: "",
      };
    case FETCH_CLIENT_ERROR:
      return {
        loading: false,
        client: [],
        error: action.payload,
      };
      
    default:
      return state;
  }
};
