import {
  FETCH_BRANCHES_ERROR,
  FETCH_BRANCHES_REQUEST,
  FETCH_BRANCHES_SUCCESS,
} from "./actionTypes";

interface InitialStateProps {
  loading: boolean;
  branches: any[];
  error: string;
}

const initialState: InitialStateProps = {
  loading: false,
  branches: [],
  error: "",
};

export const branchesReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case FETCH_BRANCHES_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case FETCH_BRANCHES_SUCCESS:
      return {
        loading: false,
        branches: action.payload,
        error: "",
      };
    case FETCH_BRANCHES_ERROR:
      return {
        loading: false,
        branches: [],
        error: action.payload,
      };

    default:
      return state;
  }
};
