import { useNavigate } from "react-router-dom";
import { user_enums } from "../enums";
import { removeUserLocalStorage } from "../helpers";

export const useLogout = () => {
  const navigate = useNavigate();

  const logout = () => {
    removeUserLocalStorage(user_enums.user);
    navigate("/login");
  };

  return {
    logout,
  };
};
