import React from "react";
import { x } from "@xstyled/emotion";
import { spacing } from "../../theme";

interface Props {
  active: boolean;
  children: React.ReactNode;
}

const NavLinkItem: React.FC<Props> = ({ children, active = false }) => {
  return (
    <x.span
      color={{ _: active ? "white" : "primary-main", hover: "white" }}
      bg={{ _: active ? "primary-main" : "white", hover: "primary-dark" }}
      py={spacing[2]}
      display='inline-block'
      minWidth='5.5rem'
      textAlign='center'
      mx={spacing[2]}
      transition='all ease-in 0.5s'
      borderRadius={spacing[2]}
      border='1px solid'
      borderColor={{ _: active ? "primary-dark" : "primary-main" }}
    >
      {children}
    </x.span>
  );
};

export default NavLinkItem;
