import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { Icon } from "@iconify/react";
import styled, { x } from "@xstyled/emotion";
import moneyLine from "@iconify/icons-majesticons/money-line";

import Grid from "../../../../components/elements/Grid";
import Dropdown from "../../../../components/elements/Drodown";
import {
  fetchClientLoanTypes, createLoanRequestByClient
} from "../../../../redux/loans/actionCreators";

import { fecthClientData } from "./../../../clients/elements/clientdetail/hooks";
import { spacing } from "../../../../theme";

import { ClientLoan, LoanTypes } from "../../../../interfaces";

interface Props {
  id: any;
}

const Wrapper = styled("div")`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
`;

const ClientLoanRequest: React.FC<Props> = ({id}) => {
  const [formData, setFormData] = useState<ClientLoan>({
    id:id,    
    loan_amt: 3500,
    loantype: null,
    processingfees: 500,
    cso: "",
    appliation_date: null,
  });

  const loantypes = useSelector((state: any) => state.loantypes);
  const client = useSelector((state: any) => state.client);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleChange = (data: Partial<ClientLoan>) => {
    setFormData((prev) => ({ ...prev, ...data }));
  };

  const handleSelectLoanType = (option: LoanTypes) => {
    handleChange({ loantype: option.id });
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    //@ts-expect-error
    await dispatch(createLoanRequestByClient(formData, navigate, id));
  };

  useEffect(() => {
    //@ts-expect-error
    dispatch(fecthClientData(id));
    //@ts-expect-error
    dispatch(fetchClientLoanTypes());
  }, [dispatch, id]);

  const loantypesData = loantypes.loantypes.map((loantype: any) => ({
    id: loantype.id,
    type: loantype.type,
  }));
  console.log(client);
  const clientData = client.client;
  console.log(clientData);

  return (
    <x.form
      onSubmit={handleSubmit}
      w='100%'
      bg='white'
      borderRadius
      minH='50vh'
      p={spacing[4]}
    >
      <x.div display='flex' alignItems='center' justifyContent='space-between'>
        <x.h3 fontSize='1.3rem' fontWeight='600' m={spacing[2]}>
          Loan application for client {clientData?.name}
        </x.h3>
        <Link to={`/client/loanhistory/${id}`}>
          <x.button
            outline={{ _: "none", focus: "none" }}
            border='1px solid'
            borderColor='transparent'
            bg={{ _: "primary-main", hover: "primary-dark" }}
            color='white'
            px={spacing[4]}
            py={spacing[2]}
            borderRadius
            mr={spacing[4]}
          >
            Back
          </x.button>
        </Link>
      </x.div>
      
      {/* <Grid container spacing={2}>
        <Grid item xs={12} sm={6} md={6} lg={6}>
          <Wrapper>
            <x.label fontSize='0.9rem' fontWeight='500' mb={spacing[2]}>
              Username
            </x.label>
            <x.div
              display='flex'
              alignItems='center'
              justifyContent='flex-start'
              mr={{ _: 0, md: spacing[2] }}
            >
              <x.div
                display='flex'
                justifyContent='center'
                alignItems='center'
                bg='secondary-400'
                w='2.275rem'
                h='2.275rem'
              >
                <Icon
                  style={{
                    color: "#3f3f46ff",
                    fontSize: "1.2rem",
                  }}
                  icon={userFilled}
                />
              </x.div>
              <x.input
                placeholder='Enter username'
                type='text'
                outline='none'
                border='1px solid'
                borderColor='secondary-200'
                w='100%'
                py={spacing[2]}
                px={spacing[2]}
                name='phone'
                onChange={(e) => handleChange({ username: e.target.value })}
                value={formData.username}
              />
            </x.div>
          </Wrapper>
        </Grid>
      </Grid> */}
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} md={6} lg={6}>
          <Wrapper>
            <x.label fontSize='0.9rem' fontWeight='500' mb={spacing[2]}>
              Loan Type
            </x.label>

            <x.div mt={-1} mr={{ _: 0, md: spacing[2] }}>
              <Dropdown<LoanTypes>
                placeholder='Select loan type'
                options={loantypesData}
                renderOption={(option) => (
                  <x.p py={1} mt={1} w='100%'>
                    {option?.type}
                  </x.p>
                )}
                optionToString={(option) => option?.type || ""}
                isActiveOption={(option, selected) => {
                  if (option && selected) {
                    return option.id === selected.id;
                  }
                  return false;
                }}
                getSelectedOption={handleSelectLoanType}
              />
            </x.div>
          </Wrapper>
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} md={6} lg={6}>
          <Wrapper>
            <x.label fontSize='0.9rem' fontWeight='500' mb={spacing[2]}>
              Amount
            </x.label>
            <x.div
              display='flex'
              alignItems='center'
              justifyContent='flex-start'
              mr={{ _: 0, md: spacing[2] }}
            >
              <x.div
                display='flex'
                justifyContent='center'
                alignItems='center'
                bg='secondary-400'
                w='2.275rem'
                h='2.275rem'
              >
                <Icon
                  style={{
                    color: "#3f3f46ff",
                    fontSize: "1.2rem",
                  }}
                  icon={moneyLine}
                />
              </x.div>
              <x.input
                placeholder='Enter amount'
                type='number'
                outline='none'
                border='1px solid'
                borderColor='secondary-200'
                w='100%'
                py={spacing[2]}
                px={spacing[2]}
                name='phone'
                onChange={(e) =>
                  handleChange({ loan_amt: Number(e.target.value) })
                }
                value={formData.loan_amt?.toString()}
              />
            </x.div>
          </Wrapper>
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} md={6} lg={6}>
          <Wrapper>
            <x.label fontSize='0.9rem' fontWeight='500' mb={spacing[2]}>
              Processing Fee
            </x.label>
            <x.div
              display='flex'
              alignItems='center'
              justifyContent='flex-start'
              mr={{ _: 0, md: spacing[2] }}
            >
              <x.div
                display='flex'
                justifyContent='center'
                alignItems='center'
                bg='secondary-400'
                w='2.275rem'
                h='2.275rem'
              >
                <Icon
                  style={{
                    color: "#3f3f46ff",
                    fontSize: "1.2rem",
                  }}
                  icon={moneyLine}
                />
              </x.div>
              <x.input
                placeholder='Enter amount'
                type='number'
                outline='none'
                border='1px solid'
                borderColor='secondary-200'
                w='100%'
                py={spacing[2]}
                px={spacing[2]}
                name='phone'
                onChange={(e) =>
                  handleChange({ processingfees: Number(e.target.value) })
                }
                value={formData.processingfees?.toString()}
              />
            </x.div>
          </Wrapper>
        </Grid>
      </Grid>
      <x.div my={spacing[4]} mx={spacing[2]}>
        <x.button
          outline={{ _: "none", focus: "none" }}
          border='1px solid'
          borderColor='transparent'
          bg={{ _: "primary-main", hover: "primary-dark" }}
          color='white'
          px={spacing[6]}
          py={spacing[2]}
          borderRadius
          type='submit'
        >
          Submit
        </x.button>
      </x.div>
    </x.form>
  );
};

export default ClientLoanRequest;
