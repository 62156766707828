import axios from "axios";
import { getToken } from "../helpers";

export const getConfig = () => {
  const token = getToken();
  
  return {
    headers: {
      "Content-Type": "application/json",
      Authorization: token ? `Bearer ${token}` : null,
    },
  };
};

export const axionInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});
